import React from "react";
import {  } from "react-router";
import { Row, Col, Button } from "antd";
import "./../LandingPage/Home.scss";
import paymentIntegration from "../../assets/images/accounting/payments-1.png";


class PaymentIntegration extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="sales-slider shape-15  py-5">
                    <div className="container mx-auto py-4">
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Plug and play Payment gateways with automated settlements</h3>
                                    <p className="sales-section-subtitle">Just imagine connecting your account a payment-gateway to receive or send money across various transactions with payment history</p>
                                    {/* <Button type="secondary" className="text-uppercase">Get Started Now</Button> */}
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <img src={paymentIntegration} alt="" className="w-100 mx-auto img-responsive" />
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="sales-features shape-11  py-5">
                    <div className="container mx-auto py-5">
                        <Row align="center" className="text-center pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <h2 className="section-title">You name it and we have it , Domestic and International Payments</h2>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default (PaymentIntegration);
