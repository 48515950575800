import React, { useState } from "react";
import ArrowIcon from './Svg_files/Arrow 28.svg';
import './FeatureList.css';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const FeatureList = ({features}) => {
   
    const [selectedFeature, setSelectedFeature] = useState(features[0]);


    const toggleFeature = (feature) => {
        setSelectedFeature(feature);
    };
    return (
        <div className='key-features-container-1'>
            <div className='key-features-container-1-heading'>Our Key Features</div>
            <div className='dest_top_view'>
                <div className='key-features-container-1-context'>
                    <div className='key-features-container-1-questions-container'>
                        <div className='key-features-container-1-questions'>
                            {features.map((feature, index) => (
                                <div
                                    key={index}
                                    onClick={() => toggleFeature(feature)}
                                    className={selectedFeature === feature ? 'active' : ''}
                                >
                                    {feature.question}
                                    <img src={ArrowIcon} alt="arrow" className="arrow-icon" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="key-features-container-1-answers">
                        {selectedFeature && (
                            <div className="key-features-container-1-answers-container">
                                <div className="key-features-container-1-answers-heading">How it Works:</div>
                                <div className="key-features-container-1-answers-description">
                                    {selectedFeature.answer}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="Mobile_view">
                <div className="accordian">
                    <div className="accordian-container">
                        <Collapse
                            accordion
                        >
                            {features.map((feature, index) => (
                                <Panel
                                    header={
                                        <div className="header-content">
                                            {feature.question}
                                        </div>
                                    }
                                    key={index}
                                    
                                >
                                    <div>{feature.answer}</div>
                                </Panel>
                            ))}
                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureList;
