// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';


// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Enhance Productivity with Smart ",
    headingSpan: " Production Planning",
    headingPart2: "  Solutions.",
    subtext: "Optimize production processes with advanced planning tools to balance demand and supply, reduce costs, and improve productivity.  "
};

const features = [
    { question: 'Master Production Scheduling', answer: 'Create detailed production schedules based on demand forecasts to optimize manufacturing processes. This feature supports effective planning by aligning production schedules with demand projections, ensuring that production activities meet market needs and operational targets.' },
    { question: 'Material Requirements Planning', answer: 'Plan material needs to avoid shortages and minimize excess inventory. This feature helps manage inventory levels efficiently by forecasting material requirements, ensuring timely procurement, and reducing the risk of stockouts and overstocking. ' },
    { question: 'Capacity Planning', answer: 'Ensure optimal use of resources and equipment to meet production targets. This feature supports effective capacity management by analyzing resource availability, scheduling equipment usage, and aligning production plans with operational capabilities to meet production goals. ' },
    { question: 'Job Sequencing and Routing', answer: 'Optimize job sequencing and routing for efficient production flow. This feature streamlines manufacturing operations by organizing tasks and processes in the most efficient order, reducing setup times, and improving overall production efficiency. ' },
    { question: 'Work Order Management', answer: 'Manage and track work orders from creation to completion. This feature supports production management by providing tools for work order scheduling, tracking progress, and ensuring that tasks are completed according to plan and specifications. ' },
    { question: 'Demand-Driven Production', answer: 'Adjust production plans based on real-time demand changes. This feature enhances flexibility by enabling rapid response to shifts in demand, ensuring that production schedules are aligned with current market needs and reducing inventory holding costs.' },
    { question: 'Production Cost Analysis', answer: 'Analyze production costs to identify cost-saving opportunities. This feature provides insights into cost drivers, enabling the evaluation of production expenses and identification of areas for cost reduction and efficiency improvements. ' },
    { question: 'Quality Control Integration', answer: 'Integrate quality checks at every stage of production to ensure product quality. This feature supports quality management by incorporating quality control measures throughout the production process, helping to maintain high standards and minimize defects.' },
    { question: 'Reporting and Analytics', answer: 'Monitor production performance with detailed reports and key performance indicators (KPIs). This feature provides valuable insights into production efficiency, cost management, and overall performance, supporting data-driven decision-making and continuous improvement. ' },
];

const ProductionPlanning = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                    <Row className="ERP_alignmentModules" gutter={16}>
                    <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                            <div className='module-top-left'>
                                <img src={Image11} alt='Employyee--'/>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />

        </React.Fragment>
    );
};

export default (ProductionPlanning);
