import React from 'react';
import { Row, Col, Card } from 'antd';
import "./Erp.scss"
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import EasyBusiness from '../LandingPage/EasyBusiness/EasyBusiness';
import EndtoendClouderp from "../LandingPage/EndtoendClouderp/EndtoendClouderp";
import Image from './Svg_files/image (1).svg'
import Customize from './Svg_files/customize.svg';
import Engineering from './Svg_files/engineering.svg';
import Analytics from './Svg_files/analytics.svg';
import Report from './Svg_files/report.svg';
import Coverage from './Svg_files/integration.svg';
import Homelandingpagebanner from '../ERP/Svg_files/BackgroundImage.svg';
import MobileApli from './Svg_files/check.svg';
import Image1 from './Svg_files/image1.svg';
import Arrow from './Svg_files/Arrow 1.svg'
import CompreoImg from './Svg_files/CompreoOffers.svg';
import Customization from './Svg_files/Customization.svg';
import Integration from './Svg_files/Integration copy.svg';
import CustomerService from './Svg_files/CustomerService.svg';
import Rectangle1 from './Svg_files/Rectangle1.svg';
import Rectangle2 from './Svg_files/Rectangle2.svg';
import Rectangle3 from './Svg_files/Rectangle3.svg';
import EsaybusinessBackground from '../EsaybusinessBackground/EsaybusinessBackground';


const Erp = () => {

    const features = [
        {
            imgSrc: Customize,
            heading: "Customization",
            subtext: "Custom screens, reports, workflows align precisely with unique business requirements."
        },
        {
            imgSrc: Engineering,
            heading: "Automation",
            subtext: "Automation tools accelerate workflows, save time, and drive impactful results."
        },
        {
            imgSrc: Analytics,
            heading: "Analytics",
            subtext: "Empower data-driven decisions with comprehensive, insightful business analytics solutions."
        },
        {
            imgSrc: Report,
            heading: "Reporting",
            subtext: "Instantly generate custom, dynamic reports with real-time data for insights."
        },
        {
            imgSrc: Coverage,
            heading: "Integrations",
            subtext: "Empower seamless digital transactions with comprehensive payment integration solutions."
        },
        {
            imgSrc: MobileApli,
            heading: "Mobile Application",
            subtext: "ERP on-the-go: Full mobile access for all business applications."
        },
    ];

    const heading = ''

    const Subheading = 'One comprehensive solution to effortlessly expand your business, unifying key operations, optimizing workflows, and delivering actionable insights to drive growth and operational excellence.'

    const cardData = [
        { title: 'Easy-to-Use Interface', content: 'An interface with a high rate of user adoption' },
        { title: 'Robotic Process Automation', content: 'Automate your recurring process/transaction flows' },
        { title: 'Multi Company Data', content: 'Seamlessly integrate between companies within group companies' },
        { title: 'Advanced Analytics & Reports', content: 'Print reports, sales, purchase documents, etc.' },
    ];

    const cards = [
        {
            title: 'OCR Capability',
            description: 'Transform documents into data',
            image: Rectangle1,

        },
        {
            title: 'Automation Tools',
            description: 'Let the software handle repetitive tasks',
            image: Rectangle2,
        },
        {
            title: 'Real-Time Analytics',
            description: 'Make informed decisions faster',
            image: Rectangle3,
        },
    ];

    const questions = [
        { question: 'Is your ERP software customizable?', answer: 'Yes, our ERP software is highly customizable to fit the unique requirements of your business.' },
        { question: 'Can your ERP software integrate with existing systems?', answer: 'Yes, our ERP software is designed to seamlessly integrate with your existing systems and third-party applications.' },
        { question: 'What features does your ERP software offer?', answer: 'Our ERP software includes modules for finance, HR, inventory management, sales, customer relationship management (CRM), and more, tailored to meet your business needs.' },
        { question: 'How long does it take to implement ERP software?', answer: 'Implementation time varies based on the complexity of your business processes, typically ranging from a few days to a month.' },
        { question: 'What kind of support do you provide?', answer: 'We offer comprehensive support, including implementation assistance, training, and ongoing technical support to ensure smooth operation.' },
        { question: 'How can I request a demo of your ERP software?', answer: 'To request a demo, please visit our "Contact Us" page or call our sales team. We’ll be happy to schedule a demonstration for you.' }
    ];

    return (
        <div className='about-parent'>
            <div className='startEngaging-parent'>
                <div className='startEngaging-container' style={{
                    backgroundImage: `url(${Homelandingpagebanner})`,
                }}>
                    <div
                        className='startEngaging-container-text'>
                        <div className='startEngaging-container-heading'>
                            Disjointed processes slowing you down? Compreo’s ERP integrates and streamlines operations.
                        </div>
                        <div className='startEngaging-container-subtext'>
                            Choose Compreo ERP for a seamless, all-in-one solution that empowers your business with real-time insights, efficient operations, and scalable growth.
                        </div>
                    </div>
                </div>
            </div>
            <div className="kf-container">
                <Row gutter={[24, 24]}>
                    <Col xs={24} md={10} lg={10} xl={10} className="kf-container-left">
                        <div className="kf-container-heading">Key Features</div>
                        <div className="kf-container-subtext">
                            ERP systems are the backbone of modern organizations, offering an integrated suite of applications to enhance processes, foster collaboration, and provide real-time visibility into business operations.
                        </div>
                    </Col>
                    <Col xs={24} md={14} lg={14} xl={14} className="kf-cards">
                        <Row gutter={[24, 24]} className="kf-cards-parent">
                            {features.map((feature, index) => (
                                <Col
                                    xs={24}
                                    sm={12}
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    key={index}
                                    className="kf-cards-container"
                                >
                                    <div className="kf-cards-container-img">
                                        <img src={feature.imgSrc} alt={feature.heading} />
                                    </div>
                                    <div className="kf-cards-container-heading">
                                        {feature.heading}
                                    </div>
                                    <div className="kf-cards-container-subtext">
                                        {feature.subtext}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="catalyst-container">
                <div className="catalyst-content">
                    <div className="catalyst-points">
                        <ul className="catalyst-points-ul">
                            <li>Compreo.ai enables commerce in best possible manner for middle and small scale businesses engaged in trade and service​</li>
                            <li>A new generation business management platform developed for the businesses with a central installation and flexible licensing options for corporates seeking to take the benefits of an effective and robust Commerce Solution ​</li>
                            <li>Start engaging with future technologies, by starting with Compreo.ai from today</li>
                            <li>Targeting to meet dynamic business needs of small and medium enterprises across business sectors.​</li>
                        </ul>
                    </div>
                    <div className="catalyst-title spacingAlignCatalist">
                        <div className="catalyst-title-heading">Catalyst for your Business Operations</div>
                        <div className="catalyst-image">
                            <img src={Image1} alt="Catalyst" />
                        </div>
                    </div>

                </div>
            </div>
            <div className='CompreoOfferAlignment'>
                <Row gutter={[16, 16]} className='OffersAlignment'>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={10}
                        xl={11}
                    >
                        <div className='OffersAlignment-image'>
                            <img src={CompreoImg} alt="CompreoOffers" className="CompreoOffers" />
                        </div>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={14}
                        xl={12}
                    >
                        <div >
                            <h2 className='ProductStandstext'>What makes our product stands out from the rest?</h2>
                            <h4 className='BusinesOffersText'>Facing disjointed processes? Our ERP unifies and streamlines all operations</h4>
                            <div className="catalyst-points backrdnone">
                                <ul className="catalyst-points-ul listSPacing">
                                    <li >
                                        {/* <img src={Customization} alt="CompreoOffers" className='OfferIcon' /> */}
                                        Customization without No-Code
                                    </li>
                                    <li >
                                        {/* <img src={Integration} alt="CompreoOffers" className='OfferIcon' /> */}
                                        Seamless Integration with your favorite Tools
                                    </li>
                                    <li >
                                        {/* <img src={CustomerService} alt="CompreoOffers" className='OfferIcon' /> */}
                                        24/7 Support from our dedicated team
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <EsaybusinessBackground cardData={cardData} Subheading={Subheading} />
            <div className="my-background">

                <h2 className='HeadingAlign'>Compreo.ai brings cutting-edge technology to your business:</h2>
                <div className="three-card-view">
                    <Row gutter={[16, 16]} className='CardAlignHoverable'>
                        {cards.map((card, index) => (
                            <Col xs={24} sm={12} md={7} key={index}>
                                <Card
                                    hoverable
                                    cover={<img alt={card.title} src={card.image} />}
                                    className="custom-card"
                                >
                                    <Card.Meta style={{ color: '#fff !important' }} className='CardData' title={card.title} description={card.description} />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            {/* {<EndtoendClouderp />} */}
            <GeneralQuestions questions={questions} />
            <NewsLetterCover />
            <Contact_us />
            <Our_branches />
        </div>
    );
};

export default Erp;
