import React, { useState } from "react";
import { Link } from 'react-router-dom';
import {  Modal } from 'antd';
import { Button } from 'antd';

import './Footer.css';
import compreo_logo from './Svg_files/Compreo Logo-01 3.svg';
import Phone from './Svg_files/mobile.svg';
import Mail from './Svg_files/email 5.svg';
import Loc from './Svg_files/gps 1.svg';
import Linkdin from './Svg_files/linkedin.svg';
import Youtube from './Svg_files/youtube.svg';
import Twitter from './Svg_files/twitter_x.svg';

const footerFeatures = {
  features: [
    "Sales & Distribution",
    "Material Management",
    "Financial Management",
    "HR & Payroll",
    "Project Management",
    "Production Planning",
    "Quality Control",
    "Project Systems",
    "Vendor Portal",
    "Dealer Portal",
    "Warehouse Management",
    "Employee Self Service Portal",
    "Inventory Management",
    "Supply Chain Management",
    "Fixed Assets",
    "Procurement",
    "Bill of Materials",
    "Club Management",
    "Project Planning",
    "E-commerce Portal",
  ],
  industry: [
    "Manufacturing",
    "Distribution & Supply Chain",
    "Retail",
    "Health Care",
    "Education",
    "Pharmaceutical",
    "Financial Services",
    "Hospitality",
    "Energy & Utilities",
    "Communication",
    "Media & Entertainment",
  ]
};

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    console.log("i am here")
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const midIndex = Math.ceil(footerFeatures.features.length / 2);
  const firstColumn = footerFeatures.features.slice(0, midIndex);
  const secondColumn = footerFeatures.features.slice(midIndex);
  const industry = footerFeatures.industry;

  return (
    <div>
      <footer className="footer">
        <div className="footer-logo">
          <img src={compreo_logo} alt="Compreo Logo" />
        </div>
        <div className="footer-container">
          <div className="footer-section">
            <div className="footer-section-container">
              <div className="footer-section-heading-1">Products</div>
              <div className="footer-links">
                <Link className="FooterAlignment" to={'/erp'}>ERP</Link>
                <Link className="FooterAlignment" to={'/pos'}>POS</Link>
                <Link className="FooterAlignment" to={'/crm'}>CRM</Link>
              </div>
            </div>
          </div>
          <div className="footer-section">
            <div className="footer-section-container">
              <div className="footer-section-heading-2">Modules</div>
              <div className="footer-features">
                <div>
                  {firstColumn.map((feature, index) => (
                    <div key={index}>
                      <Link className="FooterAlignment" to={`/${feature.replace(/&/g, '').replace(/\s+/g, '-').toLowerCase()}`}>{feature}</Link>
                    </div>
                  ))}
                </div>
                <div>
                  {secondColumn.map((feature, index) => (
                    <div key={index}>
                      <Link className="FooterAlignment" to={`/${feature.replace(/&/g, '').replace(/\s+/g, '-').toLowerCase()}`}>{feature}</Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="footer-section">
            <div className="footer-section-container">
              <div className="footer-section-heading-3">Industries</div>
              <div className="footer-industry">
                <div style={{ display: 'grid' }}>
                  {industry.map((industryItem, index) => (
                    <Link className="FooterAlignment" to={`/${industryItem.replace(/&/g, '').replace(/\s+/g, '-').toLowerCase()}`} key={index}>
                      {industryItem}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="footer-section">
            <div className="footer-section-container">
              <div className="footer-section-heading-4">Contact Us</div>
              <div className="footer-contact">
                <div className="footer-contact-container">
                  <img src={Phone} alt="Phone Icon" className="footer-contact-container-img" />
                  <div className="footer-contact-text">
                    <span>+91 9949212962</span>
                  </div>
                </div>
                <div className="footer-contact-container">
                  <img src={Mail} alt="Email Icon" />
                  <div className="footer-contact-text">
                    <a href="mailto:sales@compreo.ai">sales@compreo.ai</a>
                  </div>
                </div>
                <div className="footer-contact-container">
                  <img src={Loc} alt="Location Icon" />
                  <div>
                    <div className="footer-contact-text-1">
                      Hyderabad: <br />5th Floor, Trendz Trident, Jubilee Enclave, HITEC City, Hyderabad, Telangana 500081
                    </div>
                    <div className="footer-contact-text-1">
                      Charlotte:<br />1300 South Blvd Suite D, Charlotte, NC 28203
                    </div>
                    <div className="footer-contact-text-1">
                      Texas:<br />8195 S Custer Rd, Suite 200C, Frisco, TX 75035
                    </div>
                    <div className="footer-social">
                      <div>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/compreoai/">
                          <img src={Linkdin} alt="LinkedIn" />
                        </a>
                      </div>
                      <div>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@CompreoAI">
                          <img src={Youtube} alt="Youtube" />
                        </a>
                      </div>
                      <div>
                        <a target="_blank" rel="noopener noreferrer" href="https://x.com/CompreoAI">
                          <img src={Twitter} alt="Twitter" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div>© Copyright 2024 Compreo.AI, All Rights Reserved.</div>
          <div className="privacy-policy">
            <Button type="link" onClick={showModal}>Privacy Policy</Button>
          </div>
        </div>
      </footer>

      {/* Privacy Policy Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-container" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <div className="modal-header-heading">PRIVACY POLICY</div>
              <button className="close-modal-btn" onClick={closeModal}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-content-wrapper">
                <div className="section section-information">
                  <div className="section-title">1. Information We Collect</div>
                  <div className="section-content">
                    We collect different types of information to enhance our services and user experience:
                    <ul>
                      <li><strong>Personal Information:</strong> When you register, fill out a form, request information, or interact with our website, we may collect your name, email address, phone number, company details, and other relevant contact information.</li>
                      <li><strong>Usage Data:</strong> We collect information about your interactions with our website, including your IP address, device type, browser type, operating system, referring URLs, and pages you visit. This helps us understand how our services are used and improve performance.</li>
                      <li><strong>Cookies and Similar Technologies:</strong> We use cookies, web beacons, and other tracking technologies to collect data about your browsing habits. These technologies help us enhance functionality and provide you with a personalized experience. You can control cookie settings through your browser preferences.</li>
                    </ul>
                  </div>
                </div>

                <div className="section section-usage">
                  <div className="section-title">2. How We Use Your Information</div>
                  <div className="section-content">
                    We use the information we collect for various legitimate business purposes, including:
                    <ul>
                      <li><strong>Service Delivery:</strong> To provide, maintain, and improve our website and services, and to ensure a smooth and efficient user experience.</li>
                      <li><strong>Personalization:</strong> To customize content and enhance your user experience based on your interests and engagement with our services.</li>
                      <li><strong>Communication:</strong> To respond to your inquiries, provide customer support, send product updates, promotional materials, and newsletters, which you can opt out of at any time.</li>
                      <li><strong>Analytics and Research:</strong> To analyze user behavior, improve our offerings, and develop new features, services, and products.</li>
                      <li><strong>Compliance and Security:</strong> To comply with applicable legal obligations, enforce our terms, detect and prevent fraud, and protect the rights, property, or safety of Compreo, our users, or others.</li>
                    </ul>
                  </div>
                </div>

                <div className="section section-sharing">
                  <div className="section-title">3. Sharing Your Information</div>
                  <div className="section-content">
                    We are committed to maintaining your privacy and only share your information in specific situations:
                    <ul>
                      <li><strong>With Service Providers:</strong> We may share your information with trusted third-party vendors and service providers who help us operate our website, provide services, and conduct our business under strict confidentiality agreements.</li>
                      <li><strong>For Legal Reasons:</strong> We may disclose your information if required by law, regulation, legal process, or governmental request.</li>
                      <li><strong>Business Transactions:</strong> In the event of a merger, acquisition, reorganization, or asset sale, your information may be transferred as part of the business assets to the acquiring entity.</li>
                      <li><strong>With Your Consent:</strong> We may share information with third parties if you have given your explicit consent to do so.</li>
                    </ul>
                  </div>
                </div>

                <div className="section section-security">
                  <div className="section-title">4. Data Security</div>
                  <div className="section-content">
                    We take data security seriously and implement a range of technical, administrative, and physical safeguards to protect your information from unauthorized access, alteration, disclosure, or destruction. While we strive to use industry-standard security measures, no method of transmission or electronic storage is completely secure, and we cannot guarantee absolute security.
                  </div>
                </div>

                <div className="section section-rights">
                  <div className="section-title">5. Your Rights and Choices</div>
                  <div className="section-content">
                    You have rights regarding your personal data, and we provide choices to help you manage your privacy:
                    <ul>
                      <li><strong>Access and Correction:</strong> You can request access to your personal data and ask us to update, correct, or delete it.</li>
                      <li><strong>Opt-Out of Communications:</strong> You can opt out of receiving marketing and promotional communications by following the unsubscribe instructions in our emails.</li>
                      <li><strong>Data Deletion:</strong> You have the right to request deletion of your personal information, subject to certain legal and contractual obligations.</li>
                      <li><strong>Cookie Management:</strong> You can manage your cookie preferences through your browser settings, which may affect your experience on our website.</li>
                    </ul>
                  </div>
                </div>

                <div className="section section-international">
                  <div className="section-title">6. International Data Transfers</div>
                  <div className="section-content">
                    If you are accessing our services from outside India, please note that your data may be transferred, stored, and processed in India or other jurisdictions where we or our service providers operate. We ensure that such transfers comply with applicable data protection laws.
                  </div>
                </div>

                <div className="section section-third-party">
                  <div className="section-title">7. Third-Party Websites</div>
                  <div className="section-content">
                    Our website may contain links to third-party websites, plugins, and applications. We are not responsible for the privacy practices or content of such websites. We encourage you to review the privacy policies of every site you visit.
                  </div>
                </div>

                <div className="section section-changes">
                  <div className="section-title">8. Changes to This Privacy Policy</div>
                  <div className="section-content">
                    We may update this Privacy Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes via email or by placing a prominent notice on our website.
                  </div>
                </div>

                <div className="section section-contact">
                  <div className="section-title">9. Contact Us</div>
                  <div className="section-content">
                    If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at:
                    <strong>Email:</strong> sales@compreo.ai
                  </div>
                </div>

                <div className="section section-agreement">
                  By using our website, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.
                </div>
              </div>
            </div>

          </div>
        </div>
      )}

    </div>
  );
};

export default Footer;
