// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import ArrowIcon from './Svg_files/Arrow 28.svg';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';
import { Collapse } from 'antd';

const { Panel } = Collapse;

// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Empower Teams with Unified  ",
    headingSpan: "Employee Self Service Portal.",
    subtext: "A centralized hub for employees to access HR resources, manage tasks, and communicate efficiently, enhancing workforce engagement and productivity. "
};

const features = [
    { question: 'Self-Service Features', answer: 'Provide employees with self-service options to manage their pay slips, leave requests, and benefits information. This reduces the administrative burden on HR staff, increases efficiency, and enhances employee satisfaction by offering convenient access to personal data. ' },
    { question: 'Internal Communication Tools', answer: ' Facilitate team communication through integrated chat, forums, and announcement boards. This feature improves information flow, enhances collaboration, and helps keep employees informed and engaged with company updates. ' },
    { question: 'Task Management', answer: 'Allow employees to create, assign, and track tasks and deadlines. This feature helps improve productivity, ensures timely completion of responsibilities, and supports effective project and workload management. ' },
    { question: 'Performance Tracking', answer: 'Monitor employee performance with tools for feedback, appraisals, and goal setting. This feature supports employee development, identifies areas for improvement, and aligns performance with organizational goals for better outcomes. ' },
    { question: 'Training and Development', answer: 'Offer access to training modules and development resources to support ongoing skill enhancement and career growth. This feature helps employees stay current with industry trends and fosters professional development. ' },
    { question: 'Attendance and Time Tracking', answer: ' Automate the tracking of employee attendance, work hours, and leave requests. This feature ensures accurate payroll processing, adherence to company policies, and efficient management of employee time. ' },
    { question: 'Company Announcements', answer: 'Use a centralized platform to disseminate important company news, policy updates, and announcements. This feature keeps employees informed and engaged with the latest organizational developments. ' },
    { question: 'Document Management', answer: 'Securely store and manage important documents, policies, and forms. This feature ensures easy access to essential information, improves organization, and supports compliance with company standards. ' },
    { question: 'Feedback and Surveys', answer: 'Conduct surveys and collect feedback from employees on various aspects of their work environment. This feature facilitates continuous improvement and helps enhance overall employee experience and satisfaction. ' },
];


const EmployeePortal = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);


    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };


    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                    <Row className="ERP_alignmentModules" gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={13}>
                            <div className='module-top-left'>
                                <img src={Image11} alt='Employyee--'/>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={11}>
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />
            
        </React.Fragment>
    );
};

export default (EmployeePortal);
