import React from "react";
import { Row, Col } from 'antd';
import Source_img from './Svg_files/image (11).svg'
import Logo_img1 from './Svg_files/transaction.svg';
import Logo_img2 from './Svg_files/risk-management (1).svg';
import Logo_img3 from './Svg_files/crm.svg';
import Logo_img4 from './Svg_files/financial-report (2).svg';

import Production from './Svg_files/customer-service (1).svg'
import Operation from "./Svg_files/regulatory (1) (1).svg";
import Quality from './Svg_files/operational-risk.svg'
import resource from './Svg_files/deposit.svg'

import Analytics from './Svg_files/image (12).svg'
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';


const data = {
    industryFutureCards: [
        { id: 1, heading: "Automated Financial Transactions", subtext: "Reduces manual entry by automating transactions, enhancing speed and accuracy, lowering costs, and minimizing errors for better financial operations.", logo: Logo_img1 },
        { id: 2, heading: "Compliance and Risk Management", subtext: "Monitors regulatory requirements automatically, reducing compliance risks and ensuring financial institutions adhere to the latest standards and policies.", logo: Logo_img2 },
        { id: 3, heading: "Customer Relationship Management (CRM)", subtext: "Centralizes customer data and interactions to improve engagement, retention, and satisfaction through targeted financial products and services.", logo: Logo_img3 },
        { id: 4, heading: "Financial Reporting and Analytics", subtext: "Provides real-time, customizable reports and analytics to support strategic decision-making and enhance financial transparency and planning.", logo: Logo_img4 }
    ],
    solutionCards: [
        { id: 1, heading: "Automate Routine Transactions for Increased Efficiency", subtext: "Simplifies routine financial processes, reducing manual tasks, operational costs, and errors, leading to faster, more reliable transactions." },
        { id: 2, heading: "Ensure Compliance with Regulatory Standards", subtext: "Automates compliance tracking, minimizing the risk of regulatory penalties and ensuring adherence to evolving financial regulations." },
        { id: 3, heading: "Manage Customer Relations and Retain Clients", subtext: "Enhances client retention with an integrated CRM system that personalizes services and improves customer experiences." },
        { id: 4, heading: "Generate Insightful Financial Reports for Decision-Making", subtext: "Provides real-time, data-driven insights to support strategic planning and drive business growth." }
    ],
    benefitsCards: [
        { id: 1, heading: "Improved Customer Service", subtext: "Enhances satisfaction by reducing wait times and increasing transaction accuracy and speed.", image: Production },
        { id: 2, heading: "Enhanced Regulatory Compliance", subtext: "Automated compliance tools ensure continuous adherence to financial regulations, reducing risk.", image: Operation },
        { id: 3, heading: "Reduced Operational Risks", subtext: "Automation reduces human errors and operational risks, improving reliability and trust.", image: Quality },
        { id: 4, heading: "Optimized Financial Management", subtext: "Real-time financial data and analytics support better decision-making and strategic growth.", image: resource }
    ]
};

function FinancialServices() {
    const questions = [
        { question: 'Can Compreo handle complex regulatory reporting? ', answer: 'Yes, Compreo automates complex regulatory reporting to meet compliance standards accurately and efficiently.' },
        { question: 'Does Compreo support multi-currency transactions? ', answer: 'Yes, Compreo supports multi-currency transactions, providing flexibility for global financial operations.' },
        { question: 'Can Compreo integrate with existing banking systems? ', answer: 'Yes, Compreo easily integrates with existing banking software, ensuring seamless operations and data flow.' },
        { question: 'How does Compreo improve customer retention? ', answer: 'Compreo’s CRM offers personalized customer services, improving satisfaction and loyalty through targeted offers and efficient communication.' },
        { question: 'Does Compreo help reduce fraud risks? ', answer: 'Yes, Compreo includes risk management tools that help detect and prevent fraudulent activities.' },
        { question: 'Can Compreo generate real-time financial statements? ', answer: 'Yes, Compreo provides real-time financial statements and reports for effective financial management and decision-making.' }
    ];
    return (
        <div className="industry-parent">
            <div className="industry-your-future">
                <div className="industry-your-future-container">
                    <div className="industry-your-future-text-heading">
                        Financial Services                    </div>
                    <div className="industry-your-future-text-subtext">
                        "Empower Financial Services with Comprehensive ERP Solutions"                     </div>
                    <div className="industry-your-future-image">
                        <img src={Source_img} alt="Source" style={{ width: '100%' }} />
                    </div>
                    <div className="industry-your-future-description">
                        Compreo ERP supports financial institutions with tools for managing accounts, transactions, regulatory compliance, and customer relationships, enhancing operational efficiency and service delivery.</div>                    <div className="industry-your-future-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.industryFutureCards.map(card => (
                                <Col key={card.id} xs={24} sm={24} md={12} lg={6}>
                                    <div className="industry-your-future-cards-container">
                                        <div className="industry-your-future-cards-container-logo">
                                            <img src={card.logo} alt="Logo_alt" />
                                        </div>
                                        <div className="industry-your-future-cards-container-heading">{card.heading}</div>
                                        <div className="industry-your-future-cards-container-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-solution-parent">
                <div className="industry-solution-container">
                    <div className="industry-solution-container-heading">Ultimate solution for <span>Financial Services</span> </div>
                    <div className="industry-solution-container-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.solutionCards.map(card => (
                                <Col key={card.id} xs={24} sm={12} md={12} lg={12}>
                                    <div className="industry-solution-container-cards-item">
                                        <div className="industry-solution-container-cards-item-heading">{card.heading}</div>
                                        <div className="industry-solution-container-cards-item-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-benefits-parent">
                <div className="industry-benefits-container">
                    <div className="industry-benefits-container-left-heading">Benefits</div>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={16} lg={16}>
                            <div className="industry-benefits-container-left">

                                <div className="industry-benefits-container-left-cards">
                                    <Row gutter={[16, 16]}>
                                        {data.benefitsCards.map((card) => (
                                            <Col key={card.id} xs={24} sm={24} md={12} lg={12}>
                                                <div className="industry-benefits-container-left-cards-image">
                                                    <img className="industry-benefits-container-left-cards-image-logo" src={card.image} alt="alternative" />
                                                    <div className="industry-benefits-container-left-cards-heading">{card.heading}</div>
                                                    <div className="industry-benefits-container-left-cards-subtext">{card.subtext}</div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={8} lg={8}>
                            <div className="industry-benefits-container-right">
                                <img src={Analytics} alt="Analytics" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>


            <GeneralQuestions questions={questions} />
            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />
        </div>
    );
}

export default FinancialServices;
