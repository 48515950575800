import React from "react";
import { Row, Col, Button } from "antd";
import "./Home.scss";
import HomeSlider from "./HomeSlider/HomeSlider";
import FeaturesTab from "./FeaturesTab/FeaturesTab";
import EndtoendClouderp from "./EndtoendClouderp/EndtoendClouderp";
import GeneralQuestions from "./GeneralQuestions/GeneralQuestions";
import CompreoOffers from "./CompreoOffers/CompreoOffers";
import EdgeTechnology from './EdgeTechnology/EdgeTechnology';
import WhychooseBizNest from "../../assets/images/why-choose-biznest.png";
import ctaImage from "../../assets/images/cta-img.png";
import NewsLetterCover from './NewsLetterCover/business';
import Contact_us from './Contact_us/Contact';
import Our_branches from './Our_branches/branch';
import EasyBusiness from './EasyBusiness/EasyBusiness';



export default class Home extends React.Component {
  render() {
    const questions = [
      { question: 'What is Compreo ERP, and how does it benefit businesses?', answer: 'Compreo ERP is an integrated software solution designed to streamline business processes across various industries. It enhances operational efficiency, optimizes resource management, and supports data-driven decision-making.' },
      { question: 'Which industries does Compreo ERP cater to?', answer: 'Compreo ERP is versatile and caters to industries such as manufacturing, healthcare, education, retail, financial services, power and energy, pharma, communication, media, and more.' },
      { question: 'Can Compreo ERP be customized to suit specific business needs?', answer: 'Yes, Compreo ERP is highly customizable. It can be tailored to meet the unique requirements of different industries and business processes, ensuring optimal performance.' },
      { question: 'How does Compreo ensure data security and compliance?', answer: 'Compreo ERP adheres to strict data security protocols and complies with industry standards like GDPR and HIPAA, ensuring that sensitive business data remains secure and compliant.' },
      { question: 'What kind of support and training does Compreo offer?', answer: 'Compreo provides comprehensive support, including onboarding, training sessions, user manuals, and 24/7 customer support to ensure a smooth transition and effective use of the ERP system.' },
      { question: 'How scalable is Compreo ERP for growing businesses?', answer: 'Compreo ERP is highly scalable, allowing businesses to add modules and features as they grow. It supports small businesses to large enterprises, adapting to changing needs.' },
      { question: 'Does Compreo ERP integrate with other software systems? ', answer: 'Yes, Compreo ERP offers seamless integration with various third-party software systems, such as CRM, HR, accounting, and supply chain management tools, enhancing overall functionality.' },
      { question: 'What is the implementation timeline for Compreo ERP? ', answer: 'The implementation timeline depends on the scope and complexity of the project. However, Compreo follows a structured approach to ensure a smooth and timely deployment, often within a few weeks to months.' }
  ];
    return (
      <React.Fragment>
        {/* main slider starts */}
        <HomeSlider />
        {/* main slider ends */}

        <FeaturesTab />
        {/* <CompreoOffers />
        <EdgeTechnology />
        <EasyBusiness />
        <EndtoendClouderp /> */}
        <GeneralQuestions questions={questions}/>
        {/* <NewsLetterCover /> */}
        <Contact_us />
        <Our_branches />
       




        {/* <div className="what-we-do container mx-auto  py-5">
          <Row align="center" gutter={[10, 22]} className="my-4 text-left">
            <Col className="pr-lg-4" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <h2>Our ERP Solutions</h2>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
              <Button type="secondary">Leran More..</Button>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Row align="center" gutter={[10, 10]}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="our-works">Material Management</div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="our-works">Converge
                    Integration</div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="our-works">Sales
                    Management</div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="our-works">Financial
                    Account</div>
                </Col>

              </Row>
            </Col>
          </Row>
        </div> */}

        {/* <section className="call-to-action shape-15">
          <div className=" container mx-auto py-5">
            <Row align="center" gutter={[12, 22]}>
              <Col className="center-contents  shape-21" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <div className="middle-content">
                  <h3 className="section-title mb-2">Give your business a real boost now!</h3>
                  <p className="section-subtitle">To accomplish great things, we must not only act, but also dream; not only plan, but also believe.</p>
                 
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <img src={ctaImage} alt="" className="w-70 mx-auto img-responsive" />
              </Col>
            </Row>
          </div>
        </section> */}


      </React.Fragment>
    );
  }
}