import React, { useState, useEffect, useRef } from 'react';
import { Menu, Dropdown, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import mainlogo from "../assets/images/logo-compreo.svg";
import './Header.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';


const Header = () => {
  // const productsMenu = {
  //   "ERP": ["E-commerce portal", "Employee portal", "Vendor portal", "Customer portal", "Project planning", "Club Management", "Procurement", "Bill of materials", "Warehouse Management", "Supply chain management", "Project systems", "Production planning", "Quality management", "Fixed assets", "Project management", "Inventory control", "Sales & Distribution", "Financial Management", "HR & Payroll", "Purchase management"],
  // };

  const productsMenu = {
    "ERP": [
      { name: "Sales & Distribution", link: "sales-distribution" },
      { name: "Material Management", link: "Material-management" },
      { name: "Financial Management", link: "financial-management" },
      { name: "HR & Payroll", link: "hr-payroll" },
      { name: "Project Management", link: "project-management" },
      { name: "Production Planning", link: "production-planning" },
      { name: "Quality Control", link: "quality-control" },
      { name: "Project Systems", link: "project-systems" },
      { name: "Vendor Portal", link: "vendor-portal" },
      { name: "Dealer Portal", link: "Dealer-portal" },
      { name: "Warehouse Management", link: "warehouse-management" },
      { name: "Employee Self Service Portal", link: "Employee-Self-Service-Portal" },
      { name: "Inventory Management", link: "inventory-Management" },
      { name: "Supply Chain Management", link: "supply-chain-management" },
      { name: "Fixed Assets", link: "fixed-assets" },
      { name: "Procurement", link: "procurement" },
      { name: "Bill of Materials", link: "bill-of-materials" },
      { name: "Club Management", link: "club-management" },
      { name: "Project Planning", link: "project-planning" },
      { name: "E-commerce Portal", link: "e-commerce-portal" },
    ]
  };


  const navigate = useNavigate();

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [rightContent, setRightContent] = useState(false);
  const [menuVisible, setMenuVisible] = useState({
    product: false,
    about: false,
    industries: false,
  });
  const [currentSubmenu, setCurrentSubmenu] = useState(productsMenu["ERP"]);
  const [mobileSubmenu, setmobileSubmenu] = useState([]);
  const [ismobileSubmenu, setismobileSubmenu] = useState(false);
  const [productContent, setProductContent] = useState({
    heading: "Product Heading",
    paragraph: "This is a paragraph describing the selected product.",
    buttonText: "Learn More about Product"
  });
  const [aboutContent, setAboutContent] = useState({

    heading: "About Compreo Business Systems",
    paragraph: "Compreo.ai is designed to cater to a wide range of industries, providing tailored solutions that address unique operational needs. Whether you are in manufacturing, hospitality, retail, healthcare, or education, our ERP system offers specialized features to enhance efficiency, streamline processes, and improve overall management.",
    buttonText: "More",
    knowmoreLink: "about-company"

  });

  //const industriesContent = ["Manufacturing", "Distribution", "Hospitality", "Retail", "Communication", "Media & Entertainment", "Financial & Services", "Health Industry", "Education", "Power & Energy", "Pharma & Life Sciences"];

  const industriesContent = [
    { name: "Manufacturing", link: "Manufacturing" },
    { name: "Distribution & Supply Chain", link: "Distribution-Supply-Chain" },
    { name: "Retail", link: "Retail" },
    { name: "Health Care", link: "health-Care" },
    { name: "Education", link: "Education" },
    { name: "Pharmaceutical", link: "Pharmaceutical" },
    { name: "Financial Services", link: "financial-services" },
    { name: "Hospitality", link: "Hospitality" },
    { name: "Energy & Utilities", link: "Energy-Utilities" },
    { name: "Communication", link: "Communication" },
    { name: "Media & Entertainment", link: "media-entertainment" },
  ];

  const menuRef = useRef(null);
  const dropdownRefs = {
    product: useRef(null),
    about: useRef(null),
    industries: useRef(null)
  };

  const showDrawer = (submenu = []) => {
    console.log(submenu)
    setmobileSubmenu(submenu);
    setismobileSubmenu(submenu.length > 0);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setismobileSubmenu(false);
  };

  const erpSubItems = [
    { name: "Sales & Distribution", link: "sales-distribution" },
    { name: "Material Management", link: "Material-management" },
    { name: "Financial Management", link: "financial-management" },
    { name: "HR & Payroll", link: "hr-payroll" },
    { name: "Project Management", link: "project-management" },
    { name: "Production Planning", link: "production-planning" },
    { name: "Quality Control", link: "quality-control" },
    { name: "Project Systems", link: "project-systems" },
    { name: "Vendor Portal", link: "vendor-portal" },
    { name: "Dealer Portal", link: "Dealer-portal" },
    { name: "Warehouse Management", link: "warehouse-management" },
    { name: "Employee Self Service Portal", link: "Employee-Self-Service-Portal" },
    { name: "Inventory Management", link: "inventory-Management" },
    { name: "Supply Chain Management", link: "supply-chain-management" },
    { name: "Fixed Assets", link: "fixed-assets" },
    { name: "Procurement", link: "procurement" },
    { name: "Bill of Materials", link: "bill-of-materials" },
    { name: "Club Management", link: "club-management" },
    { name: "Project Planning", link: "project-planning" },
    { name: "E-commerce Portal", link: "e-commerce-portal" },
    // { name: "Purchase management", link: "purchase-management" }
  ]

  const handleMobileMenuItemClick = (item) => {
    console.log(item)
    if (item === "ERP") {
      setmobileSubmenu(erpSubItems);
    } else {
      window.location.href = `${item.toLowerCase()}`;
    }
  };


  // const mobileMenuItems = {
  //   "About": ["About Company", "why Compreo", "Our Team"],
  //   "Products": ["ERP", "POS", "CRM"],
  //   "Industries": ["Manufacturing", "Retail", "Financial Services", "Power & Energy", "Distribution", "Communication", "Health Industry", "Pharma & Life Sciences", "Hospitality", "Media & Entertainment", "Education"],
  //   "Our Technologies": [],
  //   "Contact us": [],
  // };

  const mobileMenuItems = {
    "About": [
      { name: "About Company", link: "/about-company" },
      { name: "Why Compreo", link: "/why-compreo" },
      { name: "Our Team", link: "/our-team" }
    ],
    "Products": [
      { name: "ERP", link: "/ERP" },
      { name: "POS", link: "/POS" },
      { name: "CRM", link: "/CRM" }
    ],
    "Industries": [
      { name: "Manufacturing", link: "Manufacturing" },
      { name: "Distribution & Supply Chain", link: "Distribution-Supply-Chain" },
      { name: "Retail", link: "Retail" },
      { name: "Health Care", link: "health-Care" },
      { name: "Education", link: "Education" },
      { name: "Pharmaceutical", link: "Pharmaceutical" },
      { name: "Financial Services", link: "financial-services" },
      { name: "Hospitality", link: "Hospitality" },
      { name: "Energy & Utilities", link: "Energy-Utilities" },
      { name: "Communication", link: "Communication" },
      { name: "Media & Entertainment", link: "media-entertainment" },
    ],
    "Our Technologies": [{ name: "Our Technologies", link: "/our-technologies" }], // No items to add links for
    "Contact us": [{ name: "Contact us", link: "/contact-us" }] // No items to add links for
  };



  // const mobileMenu = (
  //   <Menu mode="vertical">
  //     {ismobileSubmenu ? (
  //       mobileSubmenu.map((submenuItem, index) => (
  //         <Menu.Item key={index} onClick={() => handleMobileMenuItemClick(submenuItem)}>
  //           {submenuItem}
  //           }
  //           if (item === "Contact us" || item === "Our Technologies") {
  //             openLink(item)
  //             closeDrawer()


  //           }

  //           else {
  //             showDrawer(mobileMenuItems[item]);
  //           }
  //         }}>
  //           {item}
  //         </Menu.Item>
  //       ))
  //     )}
  //   </Menu>
  // )</Menu.Item>
  //       ))
  //     ) : (
  //       Object.keys(mobileMenuItems).map((item) => (
  //         <Menu.Item key={item} onClick={() => {
  //           if (item === "ERP") {
  //             handleMobileMenuItemClick(item); // Trigger ERP subitems in mobile drawer
  //         

  const mobileMenu = (
    <Menu mode="vertical">
      {ismobileSubmenu ? (
        mobileSubmenu.map((submenuItem, index) => (
          <Menu.Item key={index} onClick={() => handleMobileMenuItemClick(submenuItem.name == 'ERP' ? submenuItem.name : submenuItem.link)}>
            <a >{submenuItem.name}</a>
          </Menu.Item>
        ))
      ) : (
        Object.keys(mobileMenuItems).map((item) => (
          <Menu.Item key={item} onClick={() => {
            if (item === "ERP") {
              handleMobileMenuItemClick('ERP'); // Trigger ERP subitems in mobile drawer
            } else if (item === "Contact us" || item === "Our Technologies") {

              if (item === 'Contact us') {
                openLink('contact-us');
                closeDrawer();
              }
              if (item === 'Our Technologies') {
                openLink('our-technologies');
                closeDrawer();
              }
            } else {
              showDrawer(mobileMenuItems[item]);
            }
          }}>
            {item}
          </Menu.Item>
        ))
      )}
    </Menu>
  );


  const handleMenuItemHover = (item) => {
    setRightContent(false);
    setCurrentSubmenu(productsMenu[item]);
  };


  const toggleMenu = (menu) => {
    setMenuVisible(prevState => {
      const newState = {
        product: false,
        about: false,
        industries: false,
      };
      newState[menu] = !prevState[menu];
      return newState;
    });
  };

  const handleProductMenuItemClick = (item) => {
    setRightContent(true);
    const content = {
      pos: {
        heading: "Point-of-Sale",
        paragraph: "Our Point of Sale (POS) system is designed to provide seamless and efficient sales processing, combining powerful features with ease of use. It empowers businesses to handle transactions, manage inventory, and gain insights, all from one centralized platform.",
        buttonText: "More",
        knowmoreLink: "POS"
      },
      crm: {
        heading: "Customer Relationship Management",
        paragraph: "Compreo.ai’s CRM module is designed to centralize and streamline all customer-related processes, enabling your business to build stronger relationships, improve customer satisfaction, and drive growth.",
        buttonText: "More",
        knowmoreLink: "CRM"
      }
    };
    setProductContent(content[item]);
  };

  const openLink = (item) => {
    navigate(`/${item}`);
    setMenuVisible({ product: false, about: false, industries: false });
  };

  const handleAboutMenuItemClick = (item) => {
    const content = {
      About1: {
        heading: "About Compreo Business Systems",
        paragraph: "Compreo.ai is designed to cater to a wide range of industries, providing tailored solutions that address unique operational needs. Whether you are in manufacturing, hospitality, retail, healthcare, or education, our ERP system offers specialized features to enhance efficiency, streamline processes, and improve overall management.",
        buttonText: "More",
        knowmoreLink: "about-company"
      },
      About2: {
        heading: "Why choose Compreo?",
        paragraph: "Choose Compreo ERP for a seamless, all-in-one solution that empowers your business with real-time insights, efficient operations, and scalable growth.",
        buttonText: "More",
        knowmoreLink: "why-compreo"
      },
      About3: {
        heading: "Meet our Leadership",
        paragraph: "At Compreo, our team embodies a blend of visionary thinking, industry expertise, and a deep commitment to excellence. Together, they lead with integrity and purpose, ensuring that we not only meet our goals but also stay true to our mission of delivering exceptional products and services.",
        buttonText: "More",
        knowmoreLink: "our-team"
      }
    };
    setAboutContent(content[item]);
  };

  // const productMenu = (
  //   <div className='cardAlignData' ref={dropdownRefs.product}>
  //     <div id="card-left">
  //       {Object.keys(productsMenu).map((item) => (
  //         <li
  //           className='menuItem'
  //           key={item}
  //           onMouseEnter={() => {
  //             handleMenuItemHover(item);
  //           }}
  //           onClick={() => openLink(item)}
  //         >
  //           <div className='menuItemDetails'>
  //             <div className='itemTitle'>{item}</div>
  //           </div>
  //         </li>
  //       ))}

  //       <li
  //         className='menuItem'
  //         onMouseEnter={() => {
  //           handleProductMenuItemClick('pos');
  //         }}
  //       >
  //         <div className='menuItemDetails'>
  //           <div className='itemTitle' onClick={() => openLink("POS")}>POS</div>
  //         </div>
  //       </li>

  //       <li
  //         className='menuItem'
  //         onMouseEnter={() => {
  //           handleProductMenuItemClick('crm');
  //         }}
  //       >
  //         <div className='menuItemDetails'>
  //           <div className='itemTitle' onClick={() => openLink("CRM")}>CRM</div>
  //         </div>
  //       </li>
  //     </div>

  //     <div id="card-right">
  //       {!rightContent && currentSubmenu.map((submenuItem, index) => (
  //         <div
  //           className="right-textProduct"
  //           key={index}
  //           onClick={() => openLink(submenuItem)}
  //         >
  //           {submenuItem}
  //         </div>
  //       ))}

  //       {rightContent && (
  //         <div className="right-content">
  //           <h2 className='ContentHeader'>{productContent.heading}</h2>
  //           <p className='ContentParagraph'>{productContent.paragraph}</p>
  //           <div onClick={() => openLink(productContent.knowmoreLink)}>
  //             <button className='productButton'>{productContent.buttonText}</button>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );



  const productMenu = (
    <div className='cardAlignData' ref={dropdownRefs.product}>
      <div id="card-left">
        {Object.keys(productsMenu).map((category) => (
          <li
            className='menuItem'
            onMouseEnter={() => {
              handleMenuItemHover(category);
            }}
            onClick={() => openLink(category)}
          >
            <div className='menuItemDetails'>
              <div className='itemTitle'>{category}</div>
            </div>
          </li>
        ))}



        <li
          className='menuItem'
          onMouseEnter={() => {
            handleProductMenuItemClick('pos');
          }}
        >
          <div className='menuItemDetails'>
            <div className='itemTitle' onClick={() => openLink("POS")}>POS</div>
          </div>
        </li>

        <li
          className='menuItem'
          onMouseEnter={() => {
            handleProductMenuItemClick('crm');
          }}
        >
          <div className='menuItemDetails'>
            <div className='itemTitle' onClick={() => openLink("CRM")}>CRM</div>
          </div>
        </li>
      </div>

      <div id="card-right">
        {!rightContent && currentSubmenu.map((submenuItem, index) => (
          <div
            className="right-textProduct"
            key={index}
            onClick={() => openLink(submenuItem.link)}
          >
            {submenuItem.name}
          </div>
        ))}

        {rightContent && (
          <div className="right-content">
            <h2 className='ContentHeader'>{productContent.heading}</h2>
            <p className='ContentParagraph'>{productContent.paragraph}</p>
            <div onClick={() => openLink(productContent.knowmoreLink)}>
              <button className='productButton'>{productContent.buttonText}</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const aboutMenu = (
    <div className='cardAlignData' ref={dropdownRefs.about}>
      <div id="card-left">
        <li className='menuItem' onClick={() => openLink('about-company')} onMouseEnter={() => handleAboutMenuItemClick('About1')}>
          <div className='menuItemDetails'>
            <div className='itemTitle'>About Company</div>
          </div>
        </li>
        <li className='menuItem' onClick={() => openLink('why-compreo?')} onMouseEnter={() => handleAboutMenuItemClick('About2')}>
          <div className='menuItemDetails'>
            <div className='itemTitle'>Why Compreo?</div>
          </div>
        </li>
        <li className='menuItem' onClick={() => openLink('our-team')} onMouseEnter={() => handleAboutMenuItemClick('About3')}>
          <div className='menuItemDetails'>
            <div className='itemTitle'>Our Team</div>
          </div>
        </li>
      </div>
      <div id="card-rightAbout">
        <div className="right-content">
          <h2 className='ContentHeader'>{aboutContent.heading}</h2>
          <p className='ContentParagraph'>{aboutContent.paragraph}</p>
          <div onClick={() => openLink(aboutContent.knowmoreLink)}>
            <button className='productButton'>{aboutContent.buttonText}</button>
          </div>
        </div>
      </div>
    </div>
  );

  const industriesMenu = (
    <div className='industriesMenuUI' ref={dropdownRefs.industries}>
      <div className='industrieslist'>
        {industriesContent.map((submenuItem, index) => (
          <div className='indusSubMenu' key={index} onClick={() => openLink(submenuItem.link)}>
            {submenuItem.name}
          </div>
        ))}
      </div>
    </div>
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !menuRef.current?.contains(event.target) &&
        !Object.values(dropdownRefs).some(ref => ref.current?.contains(event.target))
      ) {
        setMenuVisible({ product: false, about: false, industries: false });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className='HeaderSticky'>
      <header className="header">
        <div className="left">
          <Link className="elements" to="/">
            <img src={mainlogo} alt="logo" />
          </Link>
        </div>
        <div className="right">
          <div className="desktop-menu" ref={menuRef}>
            <Dropdown overlay={aboutMenu} visible={menuVisible.about} onClick={() => toggleMenu('about')}>
              <div className="elements">
                About{' '}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`${menuVisible.about ? 'rotate-icon' : ''}`}
                />
              </div>
            </Dropdown>

            <Dropdown overlay={productMenu} visible={menuVisible.product} onClick={() => toggleMenu('product')}>
              <div className="elements">
                Product{' '}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`${menuVisible.product ? 'rotate-icon' : ''}`}
                />
              </div>
            </Dropdown>

            <Dropdown overlay={industriesMenu} visible={menuVisible.industries} onClick={() => toggleMenu('industries')}>
              <div className="elements">
                Industries{' '}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`${menuVisible.industries ? 'rotate-icon' : ''}`}
                />
              </div>
            </Dropdown>
            <div onClick={() => {
              setMenuVisible({
                product: false,
                about: false,
                industries: false,
              });
              navigate(`/our-technologies`);


            }}>
              <div className="elements">Our Technologies</div>
            </div>
            {/* <Link to="ContactForm">
              <div className='contactUsBtn'>Contact us</div>
            </Link> */}
          </div>
          <div className="mobile-menu">
            <MenuOutlined onClick={showDrawer} />
          </div>
        </div>
        <div className='contactDelete'>
          <Link to="Contact-us">
            <div className='contactUsBtn'>Contact Us</div>
          </Link>
        </div>
      </header>
      <Drawer
        title={ismobileSubmenu ? <Button onClick={() => showDrawer()}>Back</Button> : "Menu"}
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
      >
        {mobileMenu}
      </Drawer>

    </div>
  );
};

export default Header;
