// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';


// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Transform Member Engagement with our Dynamic",
    headingSpan: " Club Management",
    subtext: "An all-in-one solution to manage memberships, events, and activities for clubs and associations, improving engagement and efficiency. "
};

const features = [
    { question: 'Membership Management', answer: 'Easily manage member registrations, renewals, and profiles with a centralized system. This feature allows for streamlined administration, ensuring accurate member records, smooth renewal processes, and effective communication with members. It enhances engagement and simplifies membership management.' },
    { question: 'Inventory Management', answer: ' Streamline stock levels and material movement with centralized inventory control and stock reconciliation. This feature optimizes inventory efficiency, reduces costs, and ensures accurate tracking of materials and supplies, supporting effective inventory management and minimizing discrepancies. ' },
    { question: 'Event Scheduling and Management', answer: 'Organize and manage club events, meetings, and activities with an integrated scheduling system. This feature allows for easy planning, coordination, and execution of events, ensuring smooth operations and improved member participation and satisfaction. ' },
    { question: 'Payment Processing', answer: 'Securely process membership fees, event fees, and other payments through a reliable payment gateway. This feature ensures safe transactions, reduces payment-related issues, and simplifies financial management for memberships and events. ' },
    { question: 'Communication Tools', answer: 'Utilize integrated tools for email campaigns, newsletters, and announcements to enhance member communication. This feature supports targeted messaging, keeps members informed, and fosters engagement through timely updates and information dissemination. ' },
    { question: 'Facility Booking', answer: 'Manage reservations for club facilities such as halls, courts, and equipment with an efficient booking system. This feature ensures organized facility usage, reduces scheduling conflicts, and provides a seamless reservation experience for members. ' },
    { question: 'Reporting and Analytics', answer: 'Generate comprehensive reports on membership growth, event attendance, and financials to gain valuable insights. This feature supports data-driven decision-making, helps track performance, and provides a clear overview of club operations. ' },
    { question: 'Member Self-Service Portal', answer: 'Provide a portal for members to view updates, book events, and make payments independently. This feature enhances self-service capabilities, improves member experience, and reduces administrative workload by allowing members to manage their interactions with the club. ' },
    { question: 'Document Management', answer: 'Centralize storage for important documents, forms, and policies to ensure easy access and organization. This feature supports efficient document handling, improves compliance, and provides a secure repository for essential club information. ' },
    { question: 'Feedback and Surveys', answer: 'Conduct surveys to gather member feedback and improve services based on their input. This feature supports continuous improvement, aligns club offerings with member needs, and enhances overall satisfaction by capturing valuable insights. ' },
];

const ClubManagement = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                    <Row className="ERP_alignmentModules" gutter={16}>
                    <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                            <div className='module-top-left'>
                                <img src={Image11} alt='Employyee--'/>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                              
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />
            
        </React.Fragment>
    );
};

export default (ClubManagement);
