import React from "react";
import { Row, Col } from 'antd';
import Source_img from './Svg_files/Common.svg'
import Logo_img1 from './Svg_files/reserved.svg';
import Logo_img2 from './Svg_files/rate.svg';
import Logo_img3 from './Svg_files/management.svg';
import Logo_img4 from './Svg_files/payment-processor.svg';

import Production from './Svg_files/satisfaction (1) (1).svg'
import Operation from "./Svg_files/ratings.svg";
import Quality from './Svg_files/computer.svg'
import resource from './Svg_files/control.svg'

import Analytics from './Svg_files/image (4).svg'
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';


const data = {
    industryFutureCards: [
        { id: 1, heading: "Centralized Reservation Management", subtext: "Manage reservations from multiple channels in one unified system to avoid overbookings and improve guest experience.", logo: Logo_img1 },
        { id: 2, heading: "Dynamic Pricing and Rate Management", subtext: "Automatically adjust pricing based on demand, occupancy, and market trends to maximize revenue.", logo: Logo_img2 },
        { id: 3, heading: "Comprehensive Guest Relationship Management", subtext: "Track guest preferences and history to provide personalized services and enhance guest satisfaction.", logo: Logo_img3 },
        { id: 4, heading: "Integrated Billing and Payment Processing", subtext: "Streamline billing and payments for guests, reducing errors and improving financial accuracy..", logo: Logo_img4 }
    ],
    solutionCards: [
        { id: 1, heading: "Streamline Booking Processes", subtext: "Integrate booking channels and automate reservation management to enhance guest experience and reduce administrative workload." },
        { id: 2, heading: "Manage Guest Preferences", subtext: "Use data to personalize guest services, improving satisfaction and loyalty." },
        { id: 3, heading: "Optimize Pricing Strategies", subtext: "Utilize dynamic pricing tools to maximize occupancy and revenue based on market conditions." },
        { id: 4, heading: "Efficient Billing Integration", subtext: "Simplify billing processes with integrated payment solutions, reducing errors and enhancing financial control." }
    ],
    benefitsCards: [
        { id: 1, heading: "Increased Guest Satisfaction", subtext: "Deliver personalized experiences and seamless service to enhance guest loyalty.", image: Production },
        { id: 2, heading: "Higher Occupancy Rates", subtext: "Use dynamic pricing and efficient reservation management to maintain high occupancy.", image: Operation },
        { id: 3, heading: "Reduced Operational Errors", subtext: "Automate administrative tasks to reduce manual errors and enhance operational efficiency.", image: Quality },
        { id: 4, heading: "Better Financial Control", subtext: "Streamlined billing and payment processing provide better financial oversight and accuracy.", image: resource }
    ]
};

function Hospitality() {
    const questions = [
        { question: 'Can Compreo handle multi-property management? ', answer: 'Yes, Compreo supports centralized management for multiple properties, enhancing operational control and efficiency.' },
        { question: 'How does Compreo assist with dynamic pricing? ', answer: 'Compreo automatically adjusts room rates based on demand and market trends to maximize revenue.' },
        { question: 'Does Compreo integrate with existing booking channels? ', answer: 'Yes, Compreo integrates seamlessly with various booking channels for unified reservation management.' },
        { question: 'How does Compreo enhance guest satisfaction? ', answer: 'Compreo’s CRM features help personalize guest experiences based on preferences and history.' },
        { question: 'Can Compreo manage group bookings and events? ', answer: 'Yes, Compreo supports group bookings, event management, and catering to ensure comprehensive hospitality management.' },
        { question: 'Does Compreo offer mobile app support for guests? ', answer: 'Yes, Compreo can be integrated with mobile apps for easy reservations, check-ins, and guest services.' }
    ];
    return (
        <div className="industry-parent">
            <div className="industry-your-future">
                <div className="industry-your-future-container">
                    <div className="industry-your-future-text-heading">
                        Hospitality               </div>
                    <div className="industry-your-future-text-subtext">
                        "Elevate Guest Experiences with Comprehensive Hospitality ERP"                    </div>
                    <div className="industry-your-future-image">
                        <img src={Source_img} alt="Source" style={{ width: '100%' }} />
                    </div>
                    <div className="industry-your-future-description">
                        Compreo ERP is designed to help hospitality businesses manage reservations, room bookings, guest services, and billing. It provides a unified platform to enhance guest experiences while optimizing operational efficiency.</div>
                    <div className="industry-your-future-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.industryFutureCards.map(card => (
                                <Col key={card.id} xs={24} sm={24} md={12} lg={6}>
                                    <div className="industry-your-future-cards-container">
                                        <div className="industry-your-future-cards-container-logo">
                                            <img src={card.logo} alt="Logo_alt" />
                                        </div>
                                        <div className="industry-your-future-cards-container-heading">{card.heading}</div>
                                        <div className="industry-your-future-cards-container-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-solution-parent">
                <div className="industry-solution-container">
                    <div className="industry-solution-container-heading">Ultimate solution for <span>Hospitality</span></div>
                    <div className="industry-solution-container-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.solutionCards.map(card => (
                                <Col key={card.id} xs={24} sm={12} md={12} lg={12}>
                                    <div className="industry-solution-container-cards-item">
                                        <div className="industry-solution-container-cards-item-heading">{card.heading}</div>
                                        <div className="industry-solution-container-cards-item-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-benefits-parent">
                <div className="industry-benefits-container">
                    <div className="industry-benefits-container-left-heading">Benefits</div>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={16} lg={16}>
                            <div className="industry-benefits-container-left">

                                <div className="industry-benefits-container-left-cards">
                                    <Row gutter={[16, 16]}>
                                        {data.benefitsCards.map((card) => (
                                            <Col key={card.id} xs={24} sm={24} md={12} lg={12}>
                                                <div className="industry-benefits-container-left-cards-image">
                                                    <img className="industry-benefits-container-left-cards-image-logo" src={card.image} alt="alternative" />
                                                    <div className="industry-benefits-container-left-cards-heading">{card.heading}</div>
                                                    <div className="industry-benefits-container-left-cards-subtext">{card.subtext}</div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={8} lg={8}>
                            <div className="industry-benefits-container-right">
                                <img src={Analytics} alt="Analytics" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>


            <GeneralQuestions questions={questions} />
            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />
        </div>
    );
}

export default Hospitality;
