import React from "react";
import { Row, Col } from 'antd';
import Source_img from './Svg_files/image (15).svg'
import Logo_img1 from './Svg_files/personal-information.svg';
import Logo_img2 from './Svg_files/deposit (1).svg';
import Logo_img3 from './Svg_files/online-learning.svg';
import Logo_img4 from './Svg_files/facility-management.svg';

import Production from './Svg_files/administrator.svg'
import Operation from "./Svg_files/rate (1).svg";
import Quality from './Svg_files/financial-report (3).svg'
import resource from './Svg_files/planning 1.svg'

import Analytics from './Svg_files/image (16).svg'
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';


const data = {
    industryFutureCards: [
        { id: 1, heading: "Comprehensive Student Information System", subtext: "Centralizes student data, including enrollment, grades, and attendance, improving administrative efficiency and student management.", logo: Logo_img1 },
        { id: 2, heading: "Automated Fee Collection and Financial Management", subtext: "Simplifies fee collection, tracking, and financial reporting, reducing errors and streamlining financial operations.", logo: Logo_img2 },
        { id: 3, heading: "Curriculum and Course Management", subtext: "Manages course schedules, materials, and faculty assignments, ensuring efficient academic planning and delivery.", logo: Logo_img3 },
        { id: 4, heading: "Resource and Facility Management", subtext: "Optimizes the use of educational resources and facilities, enhancing the learning environment and reducing operational costs.", logo: Logo_img4 }
    ],
    solutionCards: [
        { id: 1, heading: "Centralize and Manage Student and Faculty Information Effectively", subtext: "Integrates student and faculty data for improved administration and streamlined processes." },
        { id: 2, heading: "Automate and Streamline Fee Processing and Financial Operations", subtext: "Reduces manual errors and speeds up financial processes, improving efficiency and transparency." },
        { id: 3, heading: "Efficiently Plan and Manage Curricula and Courses", subtext: "Centralizes course planning and management, ensuring effective academic delivery and faculty utilization." },
        { id: 4, heading: "Optimize the Allocation and Management of Educational Resources", subtext: "Improves resource planning and usage, maximizing value and reducing waste." }
    ],
    benefitsCards: [
        { id: 1, heading: "Enhanced Administrative Efficiency and Accuracy", subtext: "Streamlines administrative tasks, reducing errors and improving overall operational efficiency.", image: Production },
        { id: 2, heading: "Improved Student and Faculty Satisfaction", subtext: "Efficient management of information and resources improves the academic environment for students and faculty.", image: Operation },
        { id: 3, heading: "Streamlined Financial Operations & Fee Management", subtext: "Automates financial tasks, reducing errors, enhancing transparency, and ensuring timely fee collection.", image: Quality },
        { id: 4, heading: "Better Planning and Utilization of Resources", subtext: "Efficient resource management ensures optimal use, reducing costs and improving the learning experience.", image: resource }
    ]
};

function Education() {
    const questions = [
        { question: 'Can Compreo integrate with Learning Management Systems (LMS)? ', answer: 'Yes, Compreo integrates with various LMS platforms, enhancing learning delivery and administration.' },
        { question: 'How does Compreo manage student attendance and grading? ', answer: 'Compreo automates attendance tracking and grading, improving accuracy and reducing administrative workload.' },
        { question: 'Can Compreo support online fee payments?', answer: 'Yes, Compreo supports secure online fee payments, making it convenient for students and parents.' },
        { question: 'Does Compreo help in scheduling classes and exams?', answer: 'Yes, Compreo provides tools for efficient scheduling of classes, exams, and events, optimizing academic planning.' },
        { question: 'Can Compreo manage hostel and transportation services? ', answer: 'Yes, Compreo can manage hostel allocations, transportation routes, and schedules, ensuring comprehensive campus management.' },
        { question: 'Does Compreo support multi-campus management? ', answer: 'Yes, Compreo supports multi-campus management, centralizing operations for institutions with multiple locations.' }
    ];
    return (
        <div className="industry-parent">
            <div className="industry-your-future">
                <div className="industry-your-future-container">
                    <div className="industry-your-future-text-heading">
                        Education                    </div>
                    <div className="industry-your-future-text-subtext">
                        "Revolutionize Education Management with Compreo ERP"                    </div>
                    <div className="industry-your-future-image">
                        <img src={Source_img} alt="Source" style={{ width: '100%' }} />
                    </div>
                    <div className="industry-your-future-description">
                        Compreo ERP transforms educational administration by integrating student data, financial management, course planning, and resource allocation into one cohesive system, enhancing operational efficiency and academic outcomes.</div>                    <div className="industry-your-future-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.industryFutureCards.map(card => (
                                <Col key={card.id} xs={24} sm={24} md={12} lg={6}>
                                    <div className="industry-your-future-cards-container">
                                        <div className="industry-your-future-cards-container-logo">
                                            <img src={card.logo} alt="Logo_alt" />
                                        </div>
                                        <div className="industry-your-future-cards-container-heading">{card.heading}</div>
                                        <div className="industry-your-future-cards-container-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-solution-parent">
                <div className="industry-solution-container">
                    <div className="industry-solution-container-heading">Ultimate solution for <span>Education</span> </div>
                    <div className="industry-solution-container-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.solutionCards.map(card => (
                                <Col key={card.id} xs={24} sm={12} md={12} lg={12}>
                                    <div className="industry-solution-container-cards-item">
                                        <div className="industry-solution-container-cards-item-heading">{card.heading}</div>
                                        <div className="industry-solution-container-cards-item-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-benefits-parent">
                <div className="industry-benefits-container">
                    <div className="industry-benefits-container-left-heading">Benefits</div>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={16} lg={16}>
                            <div className="industry-benefits-container-left">

                                <div className="industry-benefits-container-left-cards">
                                    <Row gutter={[16, 16]}>
                                        {data.benefitsCards.map((card) => (
                                            <Col key={card.id} xs={24} sm={24} md={12} lg={12}>
                                                <div className="industry-benefits-container-left-cards-image">
                                                    <img className="industry-benefits-container-left-cards-image-logo" src={card.image} alt="alternative" />
                                                    <div className="industry-benefits-container-left-cards-heading">{card.heading}</div>
                                                    <div className="industry-benefits-container-left-cards-subtext">{card.subtext}</div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={8} lg={8}>
                            <div className="industry-benefits-container-right">
                                <img src={Analytics} alt="Analytics" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>


            <GeneralQuestions questions={questions} />
            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />
        </div>
    );
}

export default Education;
