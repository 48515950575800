import React from "react";
import { Row, Col } from 'antd';
import Source_img from './Svg_files/image (17).svg'
import Logo_img1 from './Svg_files/asset-management 1.svg';
import Logo_img2 from './Svg_files/flash.svg';
import Logo_img3 from './Svg_files/management (2).svg';
import Logo_img4 from './Svg_files/regulatory (1) (4).svg';

import Production from './Svg_files/productivity 1 (1).svg'
import Operation from "./Svg_files/save-money 1.svg";
import Quality from './Svg_files/regulatory (1) (5).svg'
import resource from './Svg_files/asset-allocation 1.svg'

import Analytics from './Svg_files/image (18).svg'
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';


const data = {
    industryFutureCards: [
        { id: 1, heading: "Comprehensive Asset Management and Maintenance", subtext: "Manages assets' lifecycle, ensuring optimal performance, minimizing breakdowns, and reducing maintenance costs through preventive maintenance and tracking systems.", logo: Logo_img1 },
        { id: 2, heading: "Real-Time Energy Production and Consumption Monitoring", subtext: "Monitors energy production and consumption data in real-time, optimizing generation and usage, and helping to balance supply and demand.", logo: Logo_img2 },
        { id: 3, heading: "Efficient Distribution and Grid Management", subtext: "Enhances grid reliability and efficiency by managing distribution networks, optimizing load balancing, and reducing energy loss.", logo: Logo_img3 },
        { id: 4, heading: "Detailed Regulatory Compliance and Reporting", subtext: "Automates compliance processes and reporting to meet industry standards, reducing the risk of penalties and ensuring operational continuity.", logo: Logo_img4 }
    ],
    solutionCards: [
        { id: 1, heading: "Optimize Asset Performance and Streamline Maintenance Processes", subtext: "Improves asset reliability and lifespan by automating maintenance schedules and reducing downtime." },
        { id: 2, heading: "Monitor and Manage Energy Production and Consumption in Real-Time", subtext: "Provides real-time insights into energy operations, optimizing resource allocation and reducing wastage." },
        { id: 3, heading: "Enhance Efficiency in Distribution and Grid Operations", subtext: "Improves grid reliability and efficiency, optimizing energy distribution, and reducing costs." },
        { id: 4, heading: "Ensure Thorough Compliance with Industry Regulations and Standards", subtext: "Automates compliance tracking, ensuring adherence to industry standards and regulations, reducing risks." }
    ],
    benefitsCards: [
        { id: 1, heading: "Increased Operational Efficiency and Reduced Downtime", subtext: "Optimizes asset usage, reduces unplanned maintenance, and enhances overall operational efficiency.", image: Production },
        { id: 2, heading: "Improved Energy Management and Cost Savings", subtext: "Real-time monitoring and analytics optimize energy production and consumption, reducing costs and enhancing sustainability.", image: Operation },
        { id: 3, heading: "Enhanced Regulatory Compliance and Reporting Accuracy", subtext: "Automates regulatory processes, ensuring compliance, reducing errors, and avoiding penalties.", image: Quality },
        { id: 4, heading: "Better Overall Asset and Resource Management", subtext: "Streamlines asset management, improving performance and reducing operational costs through effective resource utilization.", image: resource }
    ]
};

function Manufacturing() {
    const questions = [
        { question: 'Can Compreo support renewable energy integration? ', answer: 'Yes, Compreo supports integration with renewable energy sources, optimizing energy mix and sustainability goals.' },
        { question: 'Does Compreo offer tools for grid management? ', answer: 'Yes, Compreo provides advanced tools for managing grid operations, optimizing load balancing, and enhancing reliability.' },
        { question: 'How does Compreo assist in regulatory compliance?', answer: 'Compreo automates compliance monitoring and reporting, ensuring adherence to energy regulations and standards.' },
        { question: 'Can Compreo reduce energy production costs? ', answer: 'Yes, real-time monitoring and optimization features help reduce energy production costs and improve efficiency' },
        { question: 'Does Compreo help in preventive maintenance? ', answer: "Yes, Compreo's asset management features support preventive maintenance, reducing downtime and repair costs."},
        { question: 'Can Compreo integrate with existing energy management systems?', answer: 'Yes, Compreo integrates seamlessly with existing energy management systems, ensuring a smooth transition.' }
    ];
    return (
        <div className="industry-parent">
            <div className="industry-your-future">
                <div className="industry-your-future-container">
                    <div className="industry-your-future-text-heading">
                    Energy & Utilities                  </div>
                    <div className="industry-your-future-text-subtext">
                    "Optimize Energy Operations with Next-Gen ERP Solutions"                    </div>
                    <div className="industry-your-future-image">
                        <img src={Source_img} alt="Source" style={{ width: '100%' }} />
                    </div>
                    <div className="industry-your-future-description">
                    Compreo ERP supports power and energy sectors by providing tools for asset management, production monitoring, distribution management, and compliance tracking, ensuring efficient operations and regulatory adherence.</div>                    <div className="industry-your-future-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.industryFutureCards.map(card => (
                                <Col key={card.id} xs={24} sm={24} md={12} lg={6}>
                                    <div className="industry-your-future-cards-container">
                                        <div className="industry-your-future-cards-container-logo">
                                            <img src={card.logo} alt="Logo_alt" />
                                        </div>
                                        <div className="industry-your-future-cards-container-heading">{card.heading}</div>
                                        <div className="industry-your-future-cards-container-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-solution-parent">
                <div className="industry-solution-container">
                    <div className="industry-solution-container-heading">Ultimate solution for <span>Energy & Utilities</span></div>
                    <div className="industry-solution-container-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.solutionCards.map(card => (
                                <Col key={card.id} xs={24} sm={12} md={12} lg={12}>
                                    <div className="industry-solution-container-cards-item">
                                        <div className="industry-solution-container-cards-item-heading">{card.heading}</div>
                                        <div className="industry-solution-container-cards-item-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-benefits-parent">
                <div className="industry-benefits-container">
                <div className="industry-benefits-container-left-heading">Benefits</div>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={16} lg={16}>
                            <div className="industry-benefits-container-left">
                               
                                <div className="industry-benefits-container-left-cards">
                                    <Row gutter={[16, 16]}>
                                        {data.benefitsCards.map((card) => (
                                            <Col key={card.id} xs={24} sm={24} md={12} lg={12}>
                                                <div className="industry-benefits-container-left-cards-image">
                                                        <img className="industry-benefits-container-left-cards-image-logo"  src={card.image} alt="alternative" />
                                                    <div className="industry-benefits-container-left-cards-heading">{card.heading}</div>
                                                    <div className="industry-benefits-container-left-cards-subtext">{card.subtext}</div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={8} lg={8}>
                            <div className="industry-benefits-container-right">
                                <img src={Analytics} alt="Analytics" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>


            <GeneralQuestions questions={questions} />
            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />
        </div>
    );
}

export default Manufacturing;
