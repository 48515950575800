import React from "react";
import {  } from "react-router";
import "./FeaturesTab.scss";
import { Row, Col, Card,Button } from 'antd';
import CRM from "../../../assets/images/erpmodules/CRM.svg";
import FinancialAccounting from "../../../assets/images/erpmodules/FinancialAccounting.svg";
import FixedAssets from "../../../assets/images/erpmodules/FixedAssets.svg";
import HRPayroll from "../../../assets/images/erpmodules/HR&Payroll.svg";
import InventoryManagement from "../../../assets/images/erpmodules/InventoryManagement.svg";
import MaterialManagement from "../../../assets/images/erpmodules/MaterialManagement.svg";
import PaymentIntegrations from "../../../assets/images/erpmodules/PaymentIntegrations.svg";
import Productionplanning from "../../../assets/images/erpmodules/productionPlanning.svg";
import ProjectSystems from "../../../assets/images/erpmodules/ProjectSystems.svg";
import QualityManagement from "../../../assets/images/erpmodules/QualityManagement.svg";
import SalesDistribution from "../../../assets/images/erpmodules/sales&Distribution.svg";
import Arrow from "../../../assets/images/erpmodules/Arrow.svg";
import { Link } from "react-router-dom";


const data = [
    { title: "Sales & Distribution", icon: SalesDistribution, clickarrow: Arrow },
    { title: "Inventory Management", icon: InventoryManagement, clickarrow: Arrow },
    { title: "Financial Accounting", icon: FinancialAccounting, clickarrow: Arrow },
    { title: "Quality Management", icon: QualityManagement, clickarrow: Arrow },
    { title: "Production Planning", icon: Productionplanning, clickarrow: Arrow },
    { title: "Material Management", icon: MaterialManagement, clickarrow: Arrow },
    { title: "HR & Payroll", icon: HRPayroll, clickarrow: Arrow },
    { title: "Fixed Assets", icon: FixedAssets, clickarrow: Arrow },
    { title: "CRM", icon: CRM, clickarrow: Arrow },
    { title: "Payment Integrations", icon: PaymentIntegrations, clickarrow: Arrow },
    { title: "Project Systems", icon: ProjectSystems, clickarrow: Arrow },
];

class FeaturesTab extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section>
                    <div className='Our-Products'>
                        <div className='Our-Products-contianer'>
                            <div className='Our-Products-contianer-heading'>Our Products</div>
                            {/* <div className='Our-Products-contianer-subtext'>Find answers to common inquiries about our services, account setup, customer support, and more. If you need further assistance, please visit our "Contact Us" page.</div> */}
                            <div className='Our-Products-contianer-cards'>
                                <Row
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 60,
                                    }}
                                >
                                    <Col className="gutter-rowAlign" xs={24} sm={12} md={8} lg={8}>
                                        <div className='Our-Products-container-cards-container'>
                                            <div className='Our-Products-container-cards-heading'>ERP</div>
                                            <div className='Our-Products-container-cards-text'>
                                                Disjointed processes slowing you down? Compreo’s ERP integrates and streamlines operations.
                                            </div>
                                            <div className='Our-Products-container-cards-subtext'>
                                                Choose Compreo ERP for a seamless, all-in-one solution that empowers your business with real-time insights, efficient operations, and scalable growth.
                                            </div>
                                            <div className='Our-Products-container-cards-know-more-container'>
                                            <Link to='/ERP'><Button className="knowmore" type="primary" >More</Button></Link>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col className="gutter-rowAlign" xs={24} sm={12} md={8} lg={8}>
                                        <div className='Our-Products-container-cards-container'>
                                            <div className='Our-Products-container-cards-heading'>POS</div>
                                            <div className='Our-Products-container-cards-text'>
                                                Struggling with complex sales? Compreo’s POS integrates seamlessly across channels.
                                            </div>
                                            <div className='Our-Products-container-cards-subtext'>
                                                Empower your business with Compreo's comprehensive, integrated point-of-sale (POS) solution that streamlines operations, enhances customer experiences, and drives growth. Our POS system offers real-time inventory management, seamless payment processing, and robust analytics, ensuring efficiency and scalability to adapt to your evolving business needs effortlessly.
                                            </div>
                                            <div className='Our-Products-container-cards-know-more-container'>
                                            <Link to='/POS'><Button className="knowmore" type="primary" >More</Button></Link>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col className="gutter-rowAlign" xs={24} sm={12} md={8} lg={8}>
                                        <div className='Our-Products-container-cards-container'>
                                            <div className='Our-Products-container-cards-heading'>CRM</div>
                                            <div className='Our-Products-container-cards-text'>
                                                Difficulty managing customer data? Compreo’s CRM centralizes and simplifies interactions
                                            </div>
                                            <div className='Our-Products-container-cards-subtext'>
                                                Compreo's CRM, an intelligent technology built to streamline customer interactions, increase sales, and drive growth, will help you realize your full business potential. Our AI-powered CRM gives you an exhaustive overview of your customer data, automates routine tasks, and provides actionable insights to help you develop deeper, more valuable connections with your customers.
                                            </div>
                                            <div className='Our-Products-container-cards-know-more-container'>
                                            <Link to='/CRM'><Button className="knowmore" type="primary" >More</Button></Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default (FeaturesTab);
