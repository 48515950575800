import React, { useState } from 'react';
import { Row, Col, Button, Divider, Input } from 'antd';
import Img from "../../../assets/images/GeneralQuestions/Question_image.svg";
import Plus from "../../../assets/images/GeneralQuestions/Vector.svg";
import Minus from "../../../assets/images/GeneralQuestions/vector-.svg";
import './GeneralQuestions.css';

function GeneralQuestions({ questions }) {
    const [expandedIndex, setExpandedIndex] = useState(null);

    const handleToggle = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };



    return (
        <div style={{ background: '#e9f8f7' }}>
            <div className='container-1'>
                <div className='container-heading'>General Questions</div>
                <div className='container-sub-heading'>
                    Find answers to common inquiries about our services, account setup, customer support, and more. If you need further assistance,
                    please visit our "Contact Us" page.
                </div>
            </div>
            <div className='container-2 generalQuestionsERP' style={{ background: '#fff !important' }}>
                <Row className="custom-row" gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="gutter-row" style={{ display: 'grid' }}>
                        <div className='container-2-left'>
                            {questions.map((item, index) => (
                                <div key={index} className='question-container'>
                                    <div className='question' onClick={() => handleToggle(index)}>
                                        {item.question}
                                        <img
                                            src={expandedIndex === index ? Minus : Plus}
                                            alt='Toggle'
                                            className='toggle-icon'
                                        />
                                    </div>
                                    {expandedIndex === index && (
                                        <div className='answer'>
                                            {item.answer}
                                        </div>
                                    )}
                                    <Divider></Divider>
                                </div>
                            ))}
                        </div>
                    </Col>
                    {/* <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row" >
                        <div className='container-2-right'>
                            <img src={Img} alt='Any Query?' />
                            <div className='container-2-right-heading'>Any Query?</div>
                            <div className='container-2-right-discription'>
                                Still having queries on our Product? No worries. Let us know your query; we are here to help you.
                            </div>
                            <Input.TextArea style={{
                                border: '2px solid #2dccc2',
                                borderRadius: '5px'
                            }} rows={6} className='input' placeholder="Let us know" />
                            <Button className='button'>Submit</Button>
                            
                            
  
   
                        </div>
                    </Col> */}
                </Row>
            </div>
        </div>
    );
}

export default GeneralQuestions;
