import React from "react";
import {  } from "react-router";
import "./HomeSlider.scss";
import landingRightSideImage from '../../../assets/images/landingRightSideImage.svg';
import Homelandingpagebanner from '../../../assets/images/Homelandingpagebanner.svg';
import { Row, Col, Button } from 'antd';
import { Link } from "react-router-dom";

class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section>
          <div style={{
            backgroundImage: `url(${Homelandingpagebanner})`,
            backgroundSize: 'cover',
          }} className="bussiness-slider">
            <div className="app-container">
              <Row style={{ justifyContent: 'space-between' }}>
                <Col xs={24} sm={24} md={14} lg={14} xl={14} className="col-left">
                  <div className="content">
                    <div className="banner-content">
                      <h1 className="bannerH1">
                        A <span style={{ color: '#2CBCB4' }}>cloud ERP</span> to run your business seamlessly
                      </h1>
                      <h2 className="SubHeaderText"></h2>
                      <p className="baneerparagraph">
                        Run your business efficiently with cloud ERP.
                        <div> Our customized approach addresses your distinct challenges</div>
                      </p>
                      <div>
                        <Link to='/ERP'><Button className="knowmore" type="primary" >More</Button></Link>
                        
                        {/* <Button className="watchVideo" type="dashed" size="large">
                          Watch Video
                        </Button> */}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={9} className="ImgVerticalALign">
                  <div className="content">
                    <img src={landingRightSideImage} className="img-fluid" alt="home-image-itop" />
                  </div>
                </Col>
              </Row>
              <div className="usersmoduleAlignmment">
                <Row gutter={[16, 16]} className="usersCount">
                  {/* <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                    <div className="usersSpacing">
                      <h2 className="colorPrimary">10,000+</h2>
                      <h2 className="userTextAlignment">Users</h2>
                    </div>
                  </Col> */}
                  <Col xs={24} sm={24} md={19} lg={19} xl={19}>
                    <div className="ProvenDescription">
                      <div className="provenColorCode">Proven solutions for 10,000+ Users.</div>
                      <div>
                        Our ERP software is designed to scale effortlessly, handling the complexities of large enterprises with ease. With a proven track record of supporting over 10,000 users.
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default (HomeSlider);
