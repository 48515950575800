import React from 'react';
import { Row, Col, Card } from 'antd';
import './EsaybusinessBackground.css'
import Arrow from './Svg_files/Arrow 1.svg'


function EsaybusinessBackground({ cardData,Subheading }) {
   



    return (
        <div className="EsaybusinessBackground">
        <Row gutter={16}>
            <Col xs={24} md={10} className="EsabusinessLeftontent" >
                <div>
                    <h2 className="HeaderALignfont">One end-to-end solution to grow your business with ease</h2>
                    <p className="Paraalignmentfont">
                        {Subheading}
                    </p>
                </div>
                {/* <span className="RequestcallbackBtnPlain"><div className='MarginRequestALign'>Request a call Back </div>
                    <img src={Arrow} alt="" className=" mx-auto img-responsive" />
                </span> */}
            </Col>
            <Col xs={24} md={14} className="EasybusinessCard">
                <Row gutter={16}>
                    {cardData.map((card, index) => (
                        <Col key={index} xs={24} sm={24} md={24} lg={24} style={{ marginBottom: '1rem' }}>
                            <Card
                                title={card.title}
                                bordered={false}
                                className="DatacardcontentAlign"
                                style={{
                                    width: '100%', borderRadius: '10px', boxShadow: '0px 0px 7px 0px #00000026', padding: '2rem 1rem'
                                }}
                            >
                                <p>{card.content}</p>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    </div>
    );
}

export default EsaybusinessBackground;