import React from "react";
import './business.css';
import { Row, Col, Button, Input, Space } from 'antd';

function Business() {
  return (
    <div className='business-section'>
      <div className='business-header'>Give your business a real boost now!</div>
      <div className='business-subheader'>
        To accomplish great things, we must not only act, but also dream;
        not only plan, but also believe.
      </div>
      <div className='business-content'>
        <Row gutter={16} align="middle">
          <Col xs={24} sm={12} md={10}>
            <div className='business-left-content'>
              Join Our Newsletter
            </div>
          </Col>
          <Col xs={24} sm={12} md={14}>
            <Space className='business-right-content' style={{background:' #e9f8f7',borderRadius:'10px', width: '100%'}}>
              <Input className="input" placeholder="Enter your Email" style={{
               background: '#e9f8f7',
                border: 'none', borderRadius:'10px'
              }} />
              <Button className="business-button JoinButton">Join</Button>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Business;
