import React from "react";
import {  } from "react-router";
import { Row, Col } from "antd";
import "./Pricing.scss";
import {
    CheckCircleOutlined,
    CloseOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';

class Pricing extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="shape-2-1 py-5" style={{ backgroundColor: '#f1fcff' }}>
                    <div className="container mx-auto">
                        <Row className="text-center py-4">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">The right price for you, to be budget friendly and hassle free</h3>
                                    <p className="sales-section-subtitle">Price that is affordable to empower your Enterprise with all data secured and stress-free cloud scalability</p>
                                </div>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="pricing-table purple">
                                    <div className="pricing-label">Save 8% Now</div>
                                    <h2>Simple Start</h2>
                                    <h5>For Startups and Beginner Enterprises</h5>
                                    <div className="pricing-features">
                                        <div className="feature icon"><CheckCircleOutlined /> Track income & expenses</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Send custom invoices/quotes</div>
                                        <div className="feature icon"><CheckCircleOutlined />  Snap & store receipts</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Insights & reports</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Connect your bank - Manage payroll</div>
                                    </div>

                                    <div className="price-tag">
                                        <span className="symbol"></span>
                                        <span className="amount"></span>
                                        <span className="after"></span>
                                    </div>
                                    <a className="price-button" href="#">Contact</a>
                                </div>
                            </Col>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="pricing-table red">
                                    <div className="pricing-label"></div>
                                    <h2>Professional</h2>
                                    <h5>For Medium Enterprises</h5>
                                    <div className="pricing-features">
                                        <div className="feature icon"><CheckCircleOutlined /> Simple start+</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Full Business Accounting</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Manage users (upto 5)</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Track Inventory & Manage budgets</div>
                                        <div className="feature icon"><CheckCircleOutlined /> CRM integration</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Manage Vendors</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Manage bills</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Print Reports</div>
                                    </div>
                                    <a className="price-button" href="#">Contact</a>
                                </div>
                            </Col>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="pricing-table gold">
                                    <div className="pricing-label"></div>
                                    <h2>Enterprise</h2>
                                    <h5>For Large Enterprises</h5>
                                    <div className="pricing-features">
                                        <div className="feature icon"><CheckCircleOutlined /> Essentials + </div>
                                        <div className="feature icon"><CheckCircleOutlined /> Customization Setup Support</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Customized Reports</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Dashboard Builder</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Point Of Sale Integration</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Multi currency</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Vendor Payouts</div>
                                        <div className="feature icon"><CheckCircleOutlined /> Multi-Level Approval</div>
                                    </div>
                                    <a className="price-button" href="#">Contact</a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="shape-2-1 py-5" style={{ backgroundColor: '#f1fcff' }}>
                    <div className="container mx-auto">
                        <Row className="text-center py-4">
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <div className="middle-content">
                                    <h3 className="section-title mb-2">Compare the plans and find your right fit</h3>
                                    <p className="sales-section-subtitle">Don't Hesitate , buy now and enjoy reliable , secure and easy to use cloud ERP </p>
                                </div>
                            </Col>
                        </Row>
                        <Row align="center" gutter={[12, 22]}>
                            <Col className="center-contents" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <table className="comparision-table">
                                    <tbody>
                                        <tr>
                                            <td className="price-blank"></td>
                                            <td className="price-blank">Starter</td>
                                            <td className="comparision-table-popular">Most popular</td>
                                            <td className="price-blank">Highly Supported</td>
                                        </tr>
                                        <tr className="comparision-table-head">
                                            <td><strong>Features List</strong></td>
                                            <td>Simple<br></br><small style={{ fontSize: '12px', fontWeight: '400' }}>Starter plan</small></td>
                                            <td className="green-width">Professional<br></br><small style={{ fontSize: '12px', fontWeight: '400' }}>Best for Medium Sized</small></td>
                                            <td className="green-width">Enterprise<br></br><small style={{ fontSize: '12px', fontWeight: '400' }}>Our complete solution</small></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Customer Management</td>
                                            <td><CloseOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Vendor Management</td>
                                            <td><CloseOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Material Management</td>
                                            <td><CloseOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Stock Reports</td>
                                            <td><CloseOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Payroll Management</td>
                                            <td><CloseOutlined /></td>
                                           
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Account Receivables</td>
                                            <td><CheckCircleOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Account Payables</td>
                                            <td><CheckCircleOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Payment Integration</td>
                                            <td><CheckCircleOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Cutomization</td>
                                            <td><CheckCircleOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Time Sheet Managemnt</td>
                                            <td><CheckCircleOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Employee Portal</td>
                                            <td><CheckCircleOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Emailing</td>
                                            <td><CheckCircleOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Reports</td>
                                            <td><CheckCircleOutlined /></td>
                                           
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Dashboards</td>
                                            <td><CheckCircleOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Quality Management</td>
                                            <td><CheckCircleOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Production Management</td>
                                            <td><CheckCircleOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Compreo CRM Integration</td>
                                            <td><CheckCircleOutlined /></td>
                                            
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td><a className="comparision-table-help"><QuestionCircleOutlined /></a>Compreo Project Management Integration</td>
                                            <td><CheckCircleOutlined /></td>
                                           
                                            <td><CheckCircleOutlined /></td>
                                            <td><CheckCircleOutlined /></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td className="price">
                                                <a href="#">Get started</a>
                                            </td>
                                            <td className="price">
                                                <a href="#">Get started</a>
                                            </td>
                                            <td className="price">
                                                <a href="#">Get started</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default (Pricing);