import React from 'react';
import { Row, Col, Card, Avatar } from 'antd';
import Venkat_pic from './Svg_files/Venkat.svg'
import Anil_pic from './Svg_files/Anil Grandhi.svg'
import Email from './Svg_files/email 2.svg'
import Facebook from './Svg_files/facebook.svg'
import Instagram from './Svg_files/insta.svg'
import Twitter from './Svg_files/twitter.png'
import Arrow from './Svg_files/Arrow 12.svg';
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from "../LandingPage/Contact_us/Contact";
import Our_branches from "../LandingPage/Our_branches/branch";

const { Meta } = Card;

const OurTeam = () => {
    return (
        <div className='business-leaders-parent'>
            <div className='business-leaders-container'>
                <div className='business-leaders-container-1'>
                    <div className='business-leaders-container-1-1'>
                        <div className='business-leaders-container-1-heading'>About our Leadership</div>
                        {/* <div className='business-leaders-container-1-view-ideology'>
                            View the Ideology <img src={Arrow} alt='arrow' />
                        </div> */}
                    </div>
                    <div className='business-leaders-container-1-subtext'>
                        With a deep passion for innovation and a commitment to excellence, the our founder’s has been instrumental in shaping the company’s direction and driving its growth. Under their leadership, the company has grown from a simple idea into a thriving business that stands out in the industry for its dedication to quality, customer satisfaction, and forward-thinking solutions.
                    </div>
                </div>

                <div className='Leaders-cards'>
                    <Row gutter={16} className="RowALignment">
                        <Col xs={24} sm={12} md={12} lg={11}>
                            <Card className="custom-card">
                                <div className="top-container">
                                    <div className="left-section">
                                        <Avatar src={Venkat_pic} shape='square' size={200} />
                                    </div>
                                    <div className="right-section">
                                        <div className="name">Venkat Duddukuri</div>
                                        <div className="designation">Founder & CEO</div>
                                        <div className="social-media">
                                            {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                                <img src={Twitter} style={{ width: '14%' }} alt="Twitter" />
                                            </a> */}
                                            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                                                <img src={Email} alt="Twitter" />
                                            </a>
                                            {/* <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                                                <img src={Instagram} alt="Instagram" />
                                            </a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-container">
                                    Venkat Duddukuri is an engineer by qualification, hailing from a small town in India. His strong passion for knowledge and an insatiable curiosity about the world led him to New Zealand, where he pursued higher studies and gained valuable work experience. In 1999, he made a significant move to the USA, driven by the ambition to chase his dreams.
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={11}>
                            <Card className="custom-card">
                                <div className="top-container">
                                    <div className="left-section">
                                        <Avatar src={Anil_pic} shape='square' size={200} />
                                    </div>
                                    <div className="right-section">
                                        <div className="name">Anil Grandhi</div>
                                        <div className="designation">Founder & COO</div>
                                        <div className="social-media">
                                            {/* <a href="https://twitter.com/anilgrandhi" target="_blank" rel="noopener noreferrer">
                                                <img src={Twitter} style={{ width: '14%' }} alt="Twitter" />
                                            </a> */}
                                            <a href="https://www.linkedin.com/in/anilgrandhi" target="_blank" rel="noopener noreferrer">
                                                <img src={Email} alt="Twitter" />
                                            </a>
                                            {/* <a href="https://www.instagram.com/anilgrandhi/" target="_blank" rel="noopener noreferrer">
                                                <img src={Instagram} alt="Instagram" />
                                            </a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-container">
                                    AG has established himself as a respected figure in business, professional, and social circles across the USA. His illustrious career in finance has seen him work with renowned companies such as PWC, SunEdison, Amazon, and Starbucks. Building on his extensive experience and expertise, AG eventually founded AG FinTax, USA, further solidifying his reputation in the industry.
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />
        </div>
    );
};

export default OurTeam;
