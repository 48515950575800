import React from 'react';

const VideoPlayer = ({ src, width = "100%", height = "315", style }) => (
    <iframe
        width={width}
        height={height}
        style={style}
        src={src}
        title="Video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
    ></iframe>
);

export default VideoPlayer;
