import React from 'react';
import './ContactForm.scss';
import { Row, Col } from 'antd';
import Robot from './Svg_files/contact_us_holding_robot.svg';
import Email from './Svg_files/email 6.png';
import Phone from './Svg_files/mobile 3.svg';
import LinkedIn from './Svg_files/linkdin.svg';
import Facebook from './Svg_files/facebook.svg';
import Instagram from './Svg_files/instagram.svg';

import Contact_img from './Svg_files/image.svg'
import Touch_img from './Svg_files/Group 53.svg'


const ContactForm = () => {
  const onFinish = (values) => {
    console.log('Form Values:', values);
  };

  return (
    <div className="contact-parent">
      <div className="contact">
        <Row gutter={16} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div className='Connect-parent'>
            <div className='Connect-container'>
              <Row gutter={[16, 16]} >
                <Col xs={24} sm={24} md={15} lg={15} xl={16} className="Connect-container-left">
                  <div className='Connect-container-header'>Connect with our Team</div>
                  <div className='Connect-container-subtext'>
                    Ready to explore new possibilities and elevate your business? Connect with our team today and discover how Compreo.ai can help you achieve your goals. We’re here to provide the expertise, support, and innovation you need to succeed.
                  </div>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={8} className="Connect-container-right">
                  <div className='Connect-container-image'>
                    <img src={Contact_img} alt="Contact_image" className="image-with-shadow" />
                  </div>
                </Col>
              </Row>
            </div>
            <div className='Ready-container'>
              <div className='Ready-container-heading'>We are ready to hear from you!</div>
              <Row gutter={[16, 16]} >
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="Ready-container-left">
                  <div className="Ready-container-left-cards">
                    <div className="Ready-container-left-cards-container">
                      <div className="Ready-container-left-card-heading">Mail ID </div>
                      <div className="Ready-container-left-card-subtext">sales@compreo.ai</div>
                    </div>
                    <div className="Ready-container-left-cards-container">
                      <div className="Ready-container-left-card-heading">Contact Number</div>
                      <div className="Ready-container-left-card-subtext">+91 9949212962</div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={16} className="Ready-container-right">
                  <div className="Ready-container-right-container">
                    <div className="Ready-container-right-heading">Branch Locations</div>
                    <div className="Ready-container-right-cards">
                      <div className="Ready-container-right-cards-container">
                        <div className="Ready-container-right-card">
                          <div className="Ready-container-right-card-heading">Hyderabad</div>
                          <div className="Ready-container-right-card-subtext">5th Floor, Trendz Trident, Jubilee Enclave, HITEC City, Hyderabad, Telangana 500081</div>
                        </div>
                      </div>
                      <div className="Ready-container-right-cards-container">
                        <div className="Ready-container-right-card">
                          <div className="Ready-container-right-card-heading">Charlotte</div>
                          <div className="Ready-container-right-card-subtext">1300 South Blvd Suite D,
                            Charlotte, NC 28203</div>
                        </div>
                      </div>
                      <div className="Ready-container-right-cards-container">
                        <div className="Ready-container-right-card">
                          <div className="Ready-container-right-card-heading">Texas</div>
                          <div className="Ready-container-right-card-subtext">8195 S Custer Rd, Suite 200C, Frisco, TX 75035</div>
                        </div>
                      </div>


                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className='get-in-touch-container'>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className='get-in-touch-container-left'>
                  <div className='get-in-touch-container-left-container'>
                    <div className='get-in-touch-container-left-heading'>Get in Touch!</div>
                    <div className='get-in-touch-container-left-subtext'>At Compreo.ai, we’re more than just a technology partner—we’re here to support your business every step of the way. Whether you have questions, need advice, or are ready to take the next big leap in your digital transformation, our team is ready to help.</div>
                    <div className='get-in-touch-container-left-images'>
                      <img src={Touch_img} alt="get-in-touch" style={{ width: '100%' }} />
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  
                    {/* <div className="form-container">
                      <form onSubmit={onFinish}>
                        <div className="form-row">
                          <div className="input-group">
                            <label htmlFor="firstName"></label>
                            <input type="text" id="firstName" name="firstName" placeholder="First Name" />
                          </div>
                          <div className="input-group">
                            <label htmlFor="lastName"></label>
                            <input type="text" id="lastName" name="lastName" placeholder="Last Name" />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="input-group">
                            <label htmlFor="companyName"></label>
                            <input type="text" id="companyName" name="companyName" placeholder="Company Name" />
                          </div>
                          <div className="input-group">
                            <label htmlFor="designation"></label>
                            <input type="text" id="designation" name="designation" placeholder="Designation" />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="input-group">
                            <label htmlFor="mailId"></label>
                            <input type="email" id="mailId" name="mailId" placeholder="E mail" />
                          </div>
                          <div className="input-group">
                            <label htmlFor="mobileNumber"></label>
                            <input type="tel" id="mobileNumber" name="mobileNumber" placeholder="Mobile Number" />
                          </div>
                        </div>
                        <div className="input-group full-width">
                          <label htmlFor="description"></label>
                          <textarea id="description" name="description" placeholder="Description (Optional)"></textarea>
                        </div>
                        <div className="input-group-submit">
                          <button type="submit">Submit</button>
                        </div>
                      </form>
                    </div> */}
                     <iframe
                     
                        src="https://crm.impacttopline.com/public/form/4PRAGA5SVTAVE8WDOH0NJ9WFHID7LU"
                        style={{ height: '100%', width: '100%', border: 'none' }}
                        frameBorder="0"
                      />
                 
                </Col>
              </Row>
            </div>
          </div>



          {/* <Col xs={24} sm={24} md={12} lg={11} xl={11}>
            <div className="contact-left">
              <div className="contact-left-1">Have questions? We have answers!</div>
              <div className="contact-left-2">
                <div className="contact-left-2-1">Get in Touch with us!!</div>
                <div className="contact-left-2-2">
                  We'd love to hear from you! Whether you have questions, feedback, or need assistance, we're here to help. Please reach out, and our team will get back to you soon.
                </div>
              </div>
              <div className="contact-left-3">
                <div className="contact-left-3-left">
                  <img src={Email} alt="Email" />
                  <div className='EmailALignment'>sales@compreo.ai</div>
                </div>
                <div className="contact-left-3-right">
                  <img src={Phone} alt="Phone" />
                  <div>+91 9949212962</div>
                </div>
              </div>
              <div className="contact-left-4">
                <div className="contact-left-4-text">Follow Us On:</div>
                <img src={LinkedIn} alt="LinkedIn" />
                <img src={Facebook} alt="Facebook" />
                <img src={Instagram} alt="Instagram" />
              </div>
            </div>
          </Col> */}

          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="form-container">
              <form onSubmit={onFinish}>
                <div className="form-row">
                  <div className="input-group">
                    <label htmlFor="firstName"></label>
                    <input type="text" id="firstName" name="firstName" placeholder="First Name" />
                  </div>
                  <div className="input-group">
                    <label htmlFor="lastName"></label>
                    <input type="text" id="lastName" name="lastName" placeholder="Last Name" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-group">
                    <label htmlFor="companyName"></label>
                    <input type="text" id="companyName" name="companyName" placeholder="Company Name" />
                  </div>
                  <div className="input-group">
                    <label htmlFor="designation"></label>
                    <input type="text" id="designation" name="designation" placeholder="Designation" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-group">
                    <label htmlFor="mailId"></label>
                    <input type="email" id="mailId" name="mailId" placeholder="Mail ID" />
                  </div>
                  <div className="input-group">
                    <label htmlFor="mobileNumber"></label>
                    <input type="tel" id="mobileNumber" name="mobileNumber" placeholder="Mobile Number" />
                  </div>
                </div>
                <div className="input-group full-width">
                  <label htmlFor="description"></label>
                  <textarea id="description" name="description" placeholder="Description (Optional)"></textarea>
                </div>
                <div className="input-group-submit">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
            <div className="robot">
              <img src={Robot} alt="robot" />
            </div>
          </Col> */}
        </Row>
      </div>
    </div>
  );
};

export default ContactForm;