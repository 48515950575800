import React from 'react';
import './Heading.css'

const Heading = ({ part1, span,part2,  subtext }) => (
    <div className='module-top-right'>
        <div className='module-top-right-heading'>
            {part1}<span>{span}</span>{part2}
        </div>
        <div className='module-top-right-subtext'>
            {subtext}
        </div>
    </div>
);

export default Heading;
