// SalesDistribution
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import './SalesDistribution.scss';
import Image11 from '../../assets/images/erpmodules/image 11.svg';


// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Enhance Sales Performance with Integrated",
    headingSpan: "Sales & Distribution Solutions",
    // headingPart2: " Excellence.",
    subtext: "Compreo's cloud-based Sales & Distribution streamlines the sales process, providing real-time insights, efficient order management, and seamless customer engagement—all accessible from any device. Empower your sales teams with tools that simplify everything from inquiry to invoicing.  "
};

const features = [
    { question: 'Inquiry and Quotation Management', answer: 'Instantly create and send professional quotations to customers for prompt responses. This feature supports the sales process by enabling quick generation and delivery of quotations, improving customer engagement and response times. ' },
    { question: 'Customer-Specific Pricing', answer: 'Tailor quotations with customized pricing for each customer to enhance sales effectiveness. This feature allows for personalized pricing based on customer-specific agreements, improving sales opportunities and customer satisfaction. ' },
    { question: 'Credit Control', answer: 'Manage customer credits efficiently to boost engagement and ensure controlled sales processes. This feature provides tools for monitoring and controlling customer credit limits, managing overdue payments, and maintaining healthy cash flow. ' },
    { question: 'Sales Order Costing', answer: 'Generate sales orders with flexible payment terms to accommodate different customer needs. This feature supports sales order management by providing options for various payment arrangements, enhancing customer flexibility and satisfaction. ' },
    { question: 'Stock Availability Check', answer: 'Verify product availability in real time while creating orders, quotations, or invoices. This feature ensures that sales processes are based on accurate stock information, reducing the risk of overselling and improving order fulfillment accuracy. ' },
    { question: 'Order Tracking and Booking', answer: 'Monitor customer orders and delivery statuses to ensure timely fulfillment. This feature provides tools for tracking order progress, managing delivery schedules, and ensuring that customer orders are fulfilled on time. ' },
    { question: 'Automated Quotation-to-Invoice Process', answer: 'Streamline the sales cycle from quotation to invoicing with automated workflows. This feature reduces manual effort and errors by automating the transition from quotation creation to invoice generation, improving efficiency and accuracy. ' },
    { question: 'Sales Order Management', answer: 'Seamlessly create and manage sales orders internally or through existing systems. This feature supports efficient sales order processing by providing tools for order creation, tracking, and management, ensuring smooth sales operations. ' },
    { question: 'Shipping Management', answer: 'Automate outbound delivery tracking with integrated actions for stock, finance, and shipping teams. This feature streamlines shipping processes by automating delivery tracking, coordinating with relevant teams, and ensuring timely dispatch of orders. ' },
    { question: 'Sales Invoicing and Payment Links', answer: 'Convert orders to invoices effortlessly and email payment links to customers. This feature simplifies the invoicing process by generating invoices from orders and providing easy-to-use payment links, facilitating prompt customer payments. ' },
   
];

const SalesDistribution = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };


    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                    <Row className="ERP_alignmentModules" gutter={16}>
                        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                            <div className='module-top-left'>
                                <img src={Image11} alt='Employyee--' />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}

                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />

        </React.Fragment>
    );
};

export default (SalesDistribution);
