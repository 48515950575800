import React from 'react';
import './empowering.css';

import Hero_img from './Svg_files/Our technologies hero section image.svg'
import CloudIcon from './Svg_files/infrastructure.svg';
import RPAIcon from './Svg_files/RPA.svg';
import PredictiveIcon from './Svg_files/predictive-chart.svg';
import WorkflowIcon from './Svg_files/Automation.svg';

const OurTechnologies = () => {
    return (
        <div className="our-technologies-parent">
            <div className="our-technologies">
                <div className="our-technologies-container">
                    <div className="our-technologies-content">
                        <div className="our-technologies-heading">
                            Empowering clients <br></br>with latest technologies to serve their customers better
                        </div>
                        <div className="our-technologies-subheading">
                            By staying at the forefront of innovation, we ensure that our clients have the tools they need to meet evolving demands and succeed in a competitive marketplace.                    </div>
                    </div>
                    <div className="our-technologies-hero-image">
                        <img src={Hero_img} alt='Hero_img' />
                    </div>
                </div>
            </div>
            {/* {<div className="our-technologies-cards">
                <div className="our-technologies-cards-container">
                    <div className="our-technologies-card-item">
                        <img src={CloudIcon} alt="Cloud Infrastructure" className="card-icon" />
                        <div className="card-title">Cloud Infrastructure</div>
                        <div className="card-description">We deploy our solutions to reliable cloud servers.</div>
                    </div>
                    <div className="our-technologies-card-item">
                        <img src={RPAIcon} alt="RPA" className="card-icon" />
                        <div className="card-title">RPA</div>
                        <div className="card-description">Included for recurring works to save time.</div>
                    </div>
                    <div className="our-technologies-card-item">
                        <img src={PredictiveIcon} alt="Predictive Analytics" className="card-icon" />
                        <div className="card-title">Predictive Analytics</div>
                        <div className="card-description">Deployed ML models based on business data.</div>
                    </div>
                    <div className="our-technologies-card-item">
                        <img src={WorkflowIcon} alt="Workflow Automation" className="card-icon" />
                        <div className="card-title">Workflow Automation</div>
                        <div className="card-description">Automate various tasks parallelly and seamlessly.</div>
                    </div>
                </div>
            </div>} */}
        </div>
    );
};

export default OurTechnologies;
