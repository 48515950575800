// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';

// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Manage Assets Seamlessly with Comprehensive ",
    headingSpan: "Fixed Asset",
    headingPart2: "Solutions.",
    subtext: "Effectively track and manage asset movements across your enterprise with Compreo's Fixed Asset Management module. Maintain accurate, up-to-date records and gain visual insights, simplifying audit preparation and enhancing asset oversight.  "
};

const features = [
    { question: 'Acquisition of Assets', answer: 'Streamline the process of acquiring assets with detailed data tracking across multiple business years. This feature supports asset management by providing tools for recording acquisitions, tracking asset details, and managing acquisition-related information over time.' },
    { question: 'Capitalization of Assets', answer: 'Effectively manage and track both tangible and intangible assets for accurate capitalization. This feature ensures proper recording of asset values, supports financial reporting, and facilitates accurate depreciation calculations and asset management. ' },
    { question: 'Asset Joint Venture (JV)', answer: 'Evaluate and manage joint venture assets, ensuring proper valuation and reporting of shared assets. This feature provides tools for managing shared assets, tracking ownership, and ensuring accurate reporting of joint venture-related assets. ' },
    { question: 'Class-wise Depreciation', answer: 'Apply multiple depreciation methods, including prorates and fixed rates, to manage asset value reduction. This feature supports flexible depreciation calculations, allowing for accurate accounting of asset depreciation based on different methods and schedules. ' },
    { question: 'Asset Revaluation', answer: 'Regularly assess asset values to stay compliant with tax regulations and financial reporting standards. This feature facilitates periodic revaluation of assets, ensuring that recorded values reflect current market conditions and comply with regulatory requirements. ' },
    { question: 'Sales of Assets', answer: 'Manage the entire asset sale process, from valuation to final disposal, ensuring up-to-date records. This feature supports the sale of assets by providing tools for valuation, managing sales transactions, and updating asset records accordingly. ' },
];

const FixedAssets = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                    <Row className="ERP_alignmentModules" gutter={16}>
                        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                            <div className='module-top-left'>
                                <img src={Image11} alt='Employyee--' />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />

        </React.Fragment>
    );
};

export default (FixedAssets);
