// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';


// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Maximize Efficiency with Advanced ",
    headingSpan: "Warehouse Management",
    subtext: "Optimize warehouse operations with advanced inventory tracking, order fulfillment, and space utilization solutions to maximize efficiency. "
};

const features = [
    { question: 'Real-Time Inventory Tracking', answer: 'Keep accurate track of stock levels with real-time updates to ensure inventory accuracy and availability. This feature supports effective inventory management, reduces discrepancies, and improves operational efficiency. ' },
    { question: 'Order Picking and Packing', answer: 'Streamline the order picking and packing process with optimized routes and packing lists. This feature enhances efficiency, reduces picking errors, and accelerates order fulfillment, improving overall warehouse productivity. ' },
    { question: 'Space Optimization', answer: 'Utilize warehouse space efficiently with slotting and storage optimization tools. This feature maximizes storage capacity, reduces retrieval times, and improves overall warehouse layout and organization. ' },
    { question: 'Barcode and RFID Integration', answer: 'Enhance accuracy and speed with barcode and RFID scanning for inventory tracking and order management. This feature supports automated data capture, reduces manual errors, and improves inventory control. ' },
    { question: 'Returns Management', answer: 'Efficiently manage returns, restocking, and refunds to handle reverse logistics smoothly. This feature streamlines the returns process, improves customer satisfaction, and supports effective inventory management. ' },
    { question: 'Cycle Counting and Audits', answer: 'Conduct regular inventory counts and audits to ensure accuracy and reconcile discrepancies. This feature supports accurate inventory records, reduces stock variances, and enhances overall warehouse management. ' },
    { question: 'Shipping and Receiving', answer: 'Simplify inbound and outbound logistics with integrated shipping solutions. This feature supports efficient processing of shipments, improves order accuracy, and enhances overall logistics operations. ' },
    { question: 'Worker Management', answer: 'Track worker productivity, manage shifts, and assign tasks efficiently to optimize warehouse operations. This feature supports effective workforce management, improves operational efficiency, and ensures timely task completion.' },
    { question: 'Reporting and Dashboards', answer: 'Use insightful dashboards and reports to monitor warehouse operations and performance. This feature provides visibility into key metrics, supports data-driven decisions, and helps improve overall warehouse efficiency. ' },
];

const WarehouseManagement = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                    <Row className="ERP_alignmentModules" gutter={16}>
                    <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                            <div className='module-top-left'>
                                <img src={Image11} alt='Employyee--'/>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />
            
        </React.Fragment>
    );
};

export default (WarehouseManagement);
