import React from "react";
import {  } from "react-router";
import { Row, Col, Button, Card, Avatar } from "antd";
import "./OurCompany.scss"
import Business_img from './Svg_files/business_systems.svg';
import Venkat_pic from './Svg_files/Venkat.svg'
import Anil_pic from './Svg_files/Anil Grandhi.svg'
import Email from './Svg_files/email 2.svg'
import Facebook from './Svg_files/facebook.svg'
import Instagram from './Svg_files/insta.svg'
import Arrow from './Svg_files/Arrow 12.svg';
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from "../LandingPage/Contact_us/Contact";
import Our_branches from "../LandingPage/Our_branches/branch";
import Com_logo from './Svg_files/Group 65.svg';
import background1 from './Svg_files/image.svg';

const { Meta } = Card;
class OurCompany extends React.Component {
    render() {
        return (
            <React.Fragment>
                <>
                    <div className='about-parent' >
                        <img className='about-text-container-image' src={background1} alt='background' />
                        <div className='about-container'>
                            <div className='about-text-container' >
                                <img src={Com_logo} alt='Compreo' />
                                <div className='about-text-container-sub-text'>We believe in combining innovation & tradition to create tomorrow’s
                                    world by assisting clients to realize the future with business intelligence and tech expertise.</div>
                            </div>
                        </div>
                    </div>
                </>
                <div className='business_system_parents'>
                    <div className='business_system_container'>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col
                                xs={{ span: 24, order: 2 }}
                                sm={{ span: 24, order: 2 }}
                                md={{ span: 15, order: 1 }}
                                lg={{ span: 15, order: 1 }}
                                xl={{ span: 15, order: 1 }}
                            >
                                <div className='business_system_container-text'>
                                    <div className='business_system_container-text-heading'>
                                        About Compreo Business Systems
                                    </div>
                                    <div className='business_system_container-text-subtext'>
                                        Compreo Business Systems India Private Limited, Hyderabad, India, epitomizes our commitment to integrating cutting-edge technologies with intelligent solutions and offers an ERP Suite for the Small and Medium Enterprises. As a leader in IT and business process solutions, Compreo is dedicated to shaping the future with innovative software that meets the evolving demands of a digital world. Our global presence, with offices in the USA and India, supports diverse industries through a comprehensive suite of services and a relentless focus on customer excellence. We harness advancements in AI, cloud, and hyper-automation to drive transformative change and deliver flexible and sustainable solutions for our clients.
                                    </div>
                                </div>
                            </Col>

                            <Col
                                xs={{ span: 24, order: 1 }}
                                sm={{ span: 24, order: 1 }}
                                md={{ span: 9, order: 2 }}
                                lg={{ span: 9, order: 2 }}
                                xl={{ span: 9, order: 2 }}
                            >
                                <div className='business_system_container-image'>
                                    <img src={Business_img} alt='right_image' style={{ width: '100%' }} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>





                <div>
                    <div>

                        <div className="title">Our strategic Components </div>


                        <div className='strategies-cards'>
                            <Row
                                className="startegicCardgridalign"
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-rowAlign" span={12} style={{
                                    // display: 'flex',
                                    // alignItems: 'center',
                                    // justifyContent: 'center'
                                }}>
                                    <div className="cardAlgn">
                                        <div className="stratagies-card-container">
                                            <div className="face face1">
                                                <div className="content">
                                                    <div className="title">Mission</div>
                                                </div>
                                            </div>
                                            <div className="face face2">
                                                <div className="content">
                                                    <div className="text">
                                                        Establish undisputed leadership in services, build innovative products for individuals & enterprises alike to foster a positive change across the globe.            </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-rowAlign" span={12} style={{
                                    // display: 'flex',
                                    // alignItems: 'center',
                                    // justifyContent: 'center'
                                }}>
                                    <div className="cardAlgn">
                                        <div className="stratagies-card-container">
                                            <div className="face face1">
                                                <div className="content">
                                                    <div className="title">Vision</div>
                                                </div>
                                            </div>
                                            <div className="face face2">
                                                <div className="content">
                                                    <div className="text">
                                                        To deliver the best experience in innovative business solutions that enhances customer business returns.            </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </div>
                </div>
                {/* <NewsLetterCover /> */}
                <Contact_us />
                <Our_branches />

            </React.Fragment>
        );
    }
}
export default (OurCompany);

