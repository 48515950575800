// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';

// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Boost Inventory Accuracy with Real-Time ",
    headingSpan: "Inventory Management",
    subtext: "Maintain optimal inventory levels and reduce costs with a precise inventory management system that offers real-time visibility and automation. "
};

const features = [
    { question: 'Stock Level Monitoring', answer: 'Track inventory levels in real-time to prevent overstocking or stock outs. This feature provides visibility into current stock quantities, allowing for timely adjustments to inventory levels and ensuring that supply meets demand without unnecessary excess. ' },
    { question: 'Reorder Point Alerts', answer: 'Set and receive alerts when inventory reaches predefined reorder points. This feature helps maintain optimal stock levels by notifying managers when inventory falls below critical thresholds, enabling timely reordering to avoid stockouts and disruptions. ' },
    { question: 'Batch and Serial Number Tracking', answer: 'Track inventory by batch or serial numbers for enhanced traceability. This feature supports detailed tracking of individual items and batches, improving inventory management, quality control, and compliance with regulatory requirements. ' },
    { question: 'ABC Analysis', answer: 'Categorize inventory into A, B, and C categories for focused management. This feature helps prioritize inventory management efforts by classifying items based on their importance and value, allowing for targeted control and optimization strategies. ' },
    { question: 'Cycle Counting', answer: 'Conduct regular cycle counts to ensure inventory accuracy. This feature supports periodic verification of inventory levels, helping to identify discrepancies, maintain accurate records, and ensure the reliability of stock data. ' },
    { question: 'Supplier Management', answer: ' Integrate with suppliers to improve stock replenishment processes. This feature facilitates better coordination with suppliers, enhancing procurement efficiency, reducing lead times, and ensuring timely availability of inventory. ' },
    { question: 'Multi-Warehouse Management', answer: 'Manage inventory across multiple warehouses or locations efficiently. This feature provides tools for overseeing inventory distribution, tracking stock levels, and optimizing storage across different facilities, improving overall inventory control. ' },
    { question: 'Demand Forecasting', answer: 'Predict inventory demand to adjust stock levels accordingly. This feature uses historical data and market trends to forecast future demand, enabling proactive inventory management and reducing the risk of overstocking or stockouts.' },
    { question: 'Inventory Valuation', answer: 'Use automated valuation methods like FIFO, LIFO, and weighted average to manage inventory costs. This feature supports accurate inventory valuation by applying different accounting methods, ensuring financial records reflect the true cost of inventory. ' },
    
];

const InventoryManagement = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                    <Row className="ERP_alignmentModules" gutter={16}>
                    <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                            <div className='module-top-left'>
                                <img src={Image11} alt='Employyee--'/>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />

        </React.Fragment>
    );
};

export default (InventoryManagement);
