import React from "react";
import './tech_stack.css' 

import Angular from './Svg_files/Ellipse 11.svg'
import Net from './Svg_files/.Net.svg'
import react from './Svg_files/React.svg'
import NodeJs from './Svg_files/nodejs.svg'
import Sql from './Svg_files/Sql.svg'
import Css from './Svg_files/CSS.svg'
import Phython from './Svg_files/bala.svg'
import Android from './Svg_files/android.svg'
import MicroSql from './Svg_files/Micro_sql.png'
import Azure from './Svg_files/Azure.svg'
import Redux from './Svg_files/Redux.svg'
import Our_tech from './Svg_files/Our_tech.svg'

const TechStack = () => {
    return(
        <div className="techstack">
            <div className="techstack-container">
                <div className="techstack-container-heading">Our Tech Stack</div>
                <div className="techstack-container-subheading">A new generation business management 
                    platform developed for the .Net, Angular & Azure – with a central installation and flexible licensing options
                    for enterprises seeking to take the benefits of an effective and robust Business Solution.
                    Start engaging with future technologies, by starting with Compreo from today. </div>
                <div className="techstack-container-main">
                    <div className="techstack-container-main-column">
                        <div className="techstack-container-main-svg"><img src={Angular} alt="Angular"/></div>
                        <div className="techstack-container-main-svg"><img src={Net} alt=".net"/></div>
                        <div className="techstack-container-main-svg"><img src={react } alt="React.js"/></div>
                        <div className="techstack-container-main-svg"><img src={NodeJs} alt="Node.js"/></div>
                        <div className="techstack-container-main-svg"><img src={Sql} alt="Sql"/></div>
                    </div>
                    <div className="techstack-container-main-column">
                        <div className="techstack-container-main-svg"><img src={Css} alt="css"/></div>
                        <div className="techstack-container-main-svg"><img src={Phython} alt="Phython"/></div>
                        <div className="techstack-container-main-svg"><img src={Android} alt="Android"/></div>
                        <div className="techstack-container-main-svg"><img src={MicroSql} alt="MicroSql"/></div>
                        <div className="techstack-container-main-svg"><img src={Azure} alt="Azure"/></div>
                    </div>
                    <div className="techstack-container-main-column">
                       
                        <div className="techstack-container-main-last">
                        <div className="techstack-container-main-last-text">OUR TECHNOLOGIES</div>
                        <img src={Our_tech} alt="Our_tech"/>
                        </div>
                    </div>
                </div>
                <div className="techstack-footer">
                    <div className="techstack-footer-container">
                        <div className="techstack-footer-contianer-text">Technology Integrated with business,you are at the right place.</div>
                    
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TechStack;
