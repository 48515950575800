import React from "react";
import { Row, Col } from 'antd';
import Source_img from './Svg_files/image (19).svg'
import Logo_img1 from './Svg_files/research-and-development.svg';
import Logo_img2 from './Svg_files/quality-assurance.svg';
import Logo_img3 from './Svg_files/regulatory (1) (6).svg';
import Logo_img4 from './Svg_files/supply-chain (3).svg';

import Production from './Svg_files/idea 1.svg'
import Operation from "./Svg_files/quality-assurance (1).svg";
import Quality from './Svg_files/regulatory (1) (7).svg'
import resource from './Svg_files/supply-chain (3).svg'

import Analytics from './Svg_files/image (20).svg'
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';


const data = {
    industryFutureCards: [
        { id: 1, heading: "R&D Management", subtext: "Streamlines R&D workflows, from planning to execution, accelerating innovation and ensuring better management of resources and timelines.", logo: Logo_img1 },
        { id: 2, heading: "Batch Production Tracking and Quality Control", subtext: "Monitors batch production in real-time, ensuring consistency, quality, and adherence to GMP (Good Manufacturing Practice) standards.", logo: Logo_img2 },
        { id: 3, heading: "Regulatory Compliance and Documentation", subtext: "Automates compliance documentation and regulatory reporting, reducing the risk of errors and non-compliance.", logo: Logo_img3 },
        { id: 4, heading: "Supply Chain and Distribution Management", subtext: "Optimizes supply chain operations, ensuring timely procurement, production, and delivery of pharmaceutical products.", logo: Logo_img4 }
    ],
    solutionCards: [
        { id: 1, heading: "Streamline R&D Processes with Integrated Management Tools", subtext: "Enhances research efficiency by centralizing data and processes, facilitating faster innovation and development." },
        { id: 2, heading: "Ensure Product Consistency and Quality with Detailed Batch Tracking", subtext: "Provides real-time tracking and control of production batches, ensuring high quality and compliance." },
        { id: 3, heading: "Simplify Compliance with Industry Regulations Through Robust Documentation", subtext: "Automates documentation processes, ensuring timely and accurate reporting to regulatory bodies." },
        { id: 4, heading: "Optimize Supply Chain Operations for Timely and Efficient Distribution", subtext: "Improves supply chain efficiency, reducing lead times and costs while ensuring product availability." }
    ],
    benefitsCards: [
        { id: 1, heading: "Accelerated Innovation and Research Outcomes", subtext: "Streamlined R&D processes lead to faster innovation and reduced time-to-market for new products.", image: Production },
        { id: 2, heading: "Enhanced Product Quality and Consistency", subtext: "Real-time batch monitoring ensures high-quality standards are consistently met.", image: Operation },
        { id: 3, heading: "Simplified Regulatory Compliance and Reporting", subtext: "Automated compliance management reduces the burden of regulatory processes, avoiding fines and ensuring market access.", image: Quality },
        { id: 4, heading: "Improved Efficiency and Reliability in Supply Chain Management", subtext: "Optimizes supply chain processes, ensuring reliability, cost efficiency, and timely delivery.", image: resource }
    ]
};

function Manufacturing() {
    const questions = [
        { question: 'Can Compreo handle clinical trial data management? ', answer: 'Yes, Compreo efficiently manages clinical trial data, ensuring accuracy and compliance with regulatory standards.' },
        { question: 'Does Compreo support Good Manufacturing Practice (GMP) compliance? ', answer: 'Yes, Compreo’s batch tracking and quality control features ensure GMP compliance for consistent product quality.' },
        { question: 'How does Compreo improve supply chain efficiency? ', answer: 'Compreo optimizes procurement, inventory management, and distribution, reducing lead times and costs.' },
        { question: 'Can Compreo assist in managing regulatory audits? ', answer: 'Yes, Compreo automates documentation and compliance checks, making regulatory audits more manageable.' },
        { question: ' Does Compreo provide tools for managing product recalls? ', answer: 'Yes, Compreo’s traceability features help manage product recalls quickly and efficiently, minimizing impact.' },
        { question: 'Can Compreo integrate with laboratory information management systems (LIMS)? ', answer: 'Yes, Compreo can integrate seamlessly with LIMS for comprehensive management of lab data and processes.' }
    ];
    return (
        <div className="industry-parent">
            <div className="industry-your-future">
                <div className="industry-your-future-container">
                    <div className="industry-your-future-text-heading">
                    Pharmaceutical                    </div>
                    <div className="industry-your-future-text-subtext">
                        "Enhance Pharmaceutical Operations with Advanced ERP Solutions"                    </div>
                    <div className="industry-your-future-image">
                        <img src={Source_img} alt="Source" style={{ width: '100%' }} />
                    </div>
                    <div className="industry-your-future-description">
                        Compreo ERP delivers specialized tools for managing pharmaceutical research, production, compliance, and distribution. It supports life sciences companies in maintaining high standards of quality, adhering to regulations, and optimizing the supply chain to drive growth and innovation.</div>
                    <div className="industry-your-future-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.industryFutureCards.map(card => (
                                <Col key={card.id} xs={24} sm={24} md={12} lg={6}>
                                    <div className="industry-your-future-cards-container">
                                        <div className="industry-your-future-cards-container-logo">
                                            <img src={card.logo} alt="Logo_alt" />
                                        </div>
                                        <div className="industry-your-future-cards-container-heading">{card.heading}</div>
                                        <div className="industry-your-future-cards-container-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-solution-parent">
                <div className="industry-solution-container">
                    <div className="industry-solution-container-heading">Ultimate solution for <span> Pharma & Lifesciences</span> </div>
                    <div className="industry-solution-container-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.solutionCards.map(card => (
                                <Col key={card.id} xs={24} sm={12} md={12} lg={12}>
                                    <div className="industry-solution-container-cards-item">
                                        <div className="industry-solution-container-cards-item-heading">{card.heading}</div>
                                        <div className="industry-solution-container-cards-item-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-benefits-parent">
                <div className="industry-benefits-container">
                    <div className="industry-benefits-container-left-heading">Benefits</div>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={16} lg={16}>
                            <div className="industry-benefits-container-left">

                                <div className="industry-benefits-container-left-cards">
                                    <Row gutter={[16, 16]}>
                                        {data.benefitsCards.map((card) => (
                                            <Col key={card.id} xs={24} sm={24} md={12} lg={12}>
                                                <div className="industry-benefits-container-left-cards-image">
                                                    <img className="industry-benefits-container-left-cards-image-logo" src={card.image} alt="alternative" />
                                                    <div className="industry-benefits-container-left-cards-heading">{card.heading}</div>
                                                    <div className="industry-benefits-container-left-cards-subtext">{card.subtext}</div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={8} lg={8}>
                            <div className="industry-benefits-container-right">
                                <img src={Analytics} alt="Analytics" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>


            <GeneralQuestions questions={questions} />
            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />
        </div>
    );
}

export default Manufacturing;
