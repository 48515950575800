// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';

// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Optimize Procurement with Smart ",
    headingSpan: "Material Management Solutions",

    subtext: "Our Material Management module streamlines the entire procurement and purchasing process, from requisition to payment, ensuring timely stock availability for production and trading. It enables strategic planning and efficient inventory control based on production and sales forecasts, helping businesses manage resources effectively.  "
};

const features = [
    { question: 'Material Types Configuration', answer: 'Customize and configure various material types to meet specific business needs. This feature allows for the creation and management of different material classifications, accommodating diverse business requirements and ensuring that materials are categorized appropriately for effective management.' },
    { question: 'Materials Groups & Sub-Groups Classification', answer: 'Organize materials into groups and sub-groups for streamlined inventory management. This feature supports efficient inventory control by categorizing materials into hierarchical groups, facilitating better tracking, management, and reporting of inventory.' },
    { question: 'Procure-to-Pay Automation Process', answer: 'Automate the entire procurement cycle, from requisition to vendor payment, for seamless operations. This feature enhances procurement efficiency by automating the steps from purchase requisition through to invoice processing and payment, reducing manual intervention and streamlining workflows.' },
    { question: 'Dual UOM Handling & Conversions', answer: 'Manage materials in multiple units of measurement with automatic conversion capabilities. This feature supports flexible inventory management by allowing for different measurement units and automatic conversion between them, accommodating diverse procurement and inventory needs.' },
    { question: 'Raw Materials and Finished Goods Control', answer: 'Track and manage raw materials and finished goods by order, batch, or lot number. This feature provides detailed tracking of inventory items, ensuring precise control and traceability of both raw materials and finished products throughout the supply chain. ' },
    { question: 'Vendors Management', answer: 'Maintain comprehensive vendor records and optimize supplier relationships for improved procurement efficiency. This feature supports vendor management by tracking supplier performance, managing contracts, and improving communication, resulting in better procurement outcomes and stronger supplier relationships. ' },
    { question: 'Item / Service / Asset Procurement', answer: 'Streamline the procurement process for items, services, and assets within a unified system. This feature integrates procurement activities for various categories, including goods, services, and assets, facilitating efficient management and tracking of all procurement activities. ' },
    { question: 'Contract Management', answer: 'Efficiently manage supplier contracts, terms, and renewals to ensure compliance and minimize risks. This feature provides tools for tracking contract details, managing renewal dates, and ensuring compliance with contractual obligations, reducing risks and improving procurement processes.' },
    { question: 'Consignment Purchases / Drop Shipping', answer: 'Handle consignment purchases and drop shipping with built-in flexibility and control. This feature supports various procurement methods by allowing for consignment arrangements and drop shipping processes, enhancing procurement flexibility and efficiency.' },
    { question: 'Capturing All Goods Movements & Postings', answer: 'Record and monitor all material movements and accounting postings accurately. This feature ensures that all inventory transactions, including receipts, transfers, and adjustments, are captured and recorded, providing accurate and comprehensive inventory and financial data.' },
    { question: 'Batch & Serial Controlled Materials', answer: 'Manage materials with batch and serial numbers for precise tracking and traceability. This feature enables detailed tracking of inventory items through batch and serial number assignments, improving control, quality management, and traceability throughout the supply chain.' },
    { question: 'Batch Characteristics and Material Characteristics', answer: 'Define and manage specific characteristics for batches and materials to maintain quality standards. This feature allows for the specification and tracking of unique characteristics for materials and batches, ensuring adherence to quality standards and facilitating quality control.' },
    { question: 'Bin Management System', answer: 'Optimize storage space and inventory management with a robust bin management system. This feature supports efficient warehouse operations by providing tools for organizing and tracking inventory within bins, enhancing storage efficiency, and improving overall inventory management.' },
];

const MaterialPurchaseManagement = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                    <Row className="ERP_alignmentModules" gutter={16}>
                        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                            <div className='module-top-left'>
                                <img src={Image11} alt='Employyee--' />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                // part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />

        </React.Fragment>
    );
};

export default (MaterialPurchaseManagement);
