// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import ArrowIcon from '../SalesDistribution/Svg_files/Arrow 28.svg';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';
import { Collapse } from 'antd';

const { Panel } = Collapse;

// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Supplier Collaboration with Smart",
    headingSpan: " Vendor Portal",
    subtext: "Streamlines vendor management and communication, improving procurement efficiency and fostering better supplier relationships. "
};

const features = [
    { question: 'Vendor Registration and Approval', answer: 'Streamline the onboarding process for new vendors with an efficient registration and approval workflow. This feature ensures that new vendors meet organizational standards and are integrated smoothly into the system. ' },
    { question: 'Order Management', answer: 'Manage purchase orders, deliveries, and returns through a centralized system. This feature enhances procurement efficiency, reduces administrative tasks, and improves vendor relationship management.  ' },
    { question: 'Communication Platform', answer: 'Provide a centralized channel for communication with vendors, including messaging and issue resolution. This feature improves interactions, facilitates effective information exchange, and strengthens vendor partnerships. ' },
    { question: 'Performance Monitoring', answer: 'Track and evaluate vendor performance using key performance indicators and compliance metrics. This feature supports data-driven evaluations, identifies areas for improvement, and ensures effective vendor management. ' },
    { question: 'Invoice and Payment Tracking', answer: 'Streamline the submission and tracking of invoices and payments. This feature ensures timely processing, accurate financial management, and smooth transactions with vendors. ' },
    { question: 'Contract Management', answer: 'Manage supplier contracts, including tracking expiration dates and renewals. This feature provides visibility into contractual obligations and supports effective contract administration and compliance.  ' },
    { question: 'Document Sharing', answer: 'SFacilitate secure sharing of necessary documents such as compliance certificates and contracts. This feature improves transparency, efficiency, and collaboration with vendors. ' },
    { question: 'Price List Management', answer: 'Maintain and update vendor price lists to negotiate effectively and manage procurement costs. This feature ensures competitive pricing and supports cost management strategies.  ' },
    { question: 'Reporting and Analytics', answer: 'Generate detailed reports on vendor performance, procurement spend, and other key metrics. This feature provides insights for strategic decision-making and procurement optimization.' },
];


const VendorPortal = (props) => {
   


    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                    <Row className="ERP_alignmentModules" gutter={16}>
                        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                            <div className='module-top-left'>
                                <img src={Image11} alt='Employyee--'/>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                       
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />
            
        </React.Fragment>
    );
};

export default (VendorPortal);
