// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';

// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Ensure Excellence with Automated",
    headingSpan: "Quality Control and Management",
    subtext: "Ensure product quality and compliance with an integrated quality management system that includes testing, inspections, and audits. "
};

const features = [
    { question: 'Quality Assurance Planning', answer: 'Define and implement quality assurance plans for processes and products. This feature supports the development of comprehensive quality plans, ensuring that quality standards are established and adhered to throughout production and service delivery. ' },
    { question: 'Inspection Management', answer: 'Schedule and manage inspections for incoming, in-process, and finished goods. This feature facilitates the systematic evaluation of products at various stages, ensuring that they meet quality standards and regulatory requirements before they reach customers.' },
    { question: 'Non-Conformance Management', answer: 'Identify, document, and manage non-conformances to prevent recurrence. This feature supports the handling of quality issues by tracking deviations from standards, implementing corrective actions, and preventing similar issues in the future. ' },
    { question: 'Corrective and Preventive Actions', answer: 'Implement CAPA to resolve quality issues effectively and prevent their recurrence. This feature provides tools for investigating problems, implementing corrective measures, and ensuring that preventive actions are taken to improve quality and compliance. ' },
    { question: 'Audit Management', answer: 'Plan, conduct, and track internal and external quality audits. This feature supports audit activities by providing tools for scheduling audits, managing findings, and ensuring compliance with quality standards and regulations. ' },
    { question: 'Supplier Quality Management', answer: 'Monitor and manage the quality performance of suppliers to ensure product quality. This feature helps evaluate and maintain supplier quality, facilitating improvements and ensuring that supplier-provided materials meet required standards. ' },
    { question: 'Compliance Tracking', answer: 'Ensure compliance with industry standards and regulations through rigorous tracking. This feature supports adherence to regulatory requirements by monitoring compliance status, tracking changes, and maintaining necessary documentation.' },
    { question: 'Quality Reporting and Dashboards', answer: 'Generate reports and dashboards for continuous quality improvement. This feature provides visual insights into quality metrics, trends, and performance, supporting ongoing quality management and enhancement efforts. ' },
    { question: 'Document Control', answer: 'Manage and control quality-related documents and records effectively. This feature ensures that quality documentation is organized, accessible, and up-to-date, supporting compliance and effective quality management. ' },
];

const QualityManagement = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                    <Row className="ERP_alignmentModules" gutter={16}>
                        <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                            <div className='module-top-left'>
                                <img src={Image11} alt='Employyee--' />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            <Contact_us />
            <Our_branches />

        </React.Fragment>
    );
};

export default (QualityManagement);
