import React from "react";
import './Adapt_tech.css'

import Left_image from './Svg_files/Rectangle 116.svg'


const AdaptTechnology = () => {
    return (
        <div className="adapttechnology">
            <div className="adapttechnology-container">
                <div className="adapttechnology-container-left">
                    <img src={Left_image} alt="Logo" style={{ width: '90%' }} />
                </div>
                <div className="adapttechnology-container-right">
                    <div className="adapttechnology-container-right-heading">We adapt latest technologies with time</div>
                    <div className="adapttechnology-container-right-subtext">With cloud as Infrastructure we have a vast opportunity to deploy different technology
                        implementations as microservices to integrate with our ERP, our technology teams are always
                        inline with latest trends and solutions</div>
                </div>
            </div>
        </div>
    );
};

export default AdaptTechnology;
